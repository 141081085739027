import React, { useEffect, useState } from "react";
import Breadcrums from "../components/breadcrums";
import Moment from "react-moment";
import { timesheetDetails } from "../api/timesheet-api";
import { nanoid } from "nanoid";
import TimesheetFilters from "../blocks/timesheetFilters";
import classNames from "classnames";

import Modal from "../components/modal";
import Skeleton from "react-loading-skeleton";
import PageTitle from "../components/pageTitle";
import { timesheetBreadcrums, timesheetDetailsBreadcrums } from "../constants/breadcrums";
import { sectionClass } from "../constants/common";
import TanstackTable from "../components/TanstackTable";
import { ColumnDef } from "@tanstack/react-table";
import { TimesheetType, consultant } from "../model";
import { useParams } from "react-router";
import TimesheetCalendar from "../components/timesheetCalendar";
import { startOfToday } from "date-fns";
import { getFinances } from "../api/finance-api";

export default function TimesheetDetails() {
    const { uuid } = useParams();
    console.log(useParams());

    return (
        <>
            <main>
                <Breadcrums breadcrumLinks={timesheetDetailsBreadcrums} module={"Timesheet"} />
                <PageTitle title={"Timesheet Details"} preSummary={"Organizational Overview"} summary={"Overview of monthly timesheet details for user " + uuid}></PageTitle>

                <div className="px-4 py-6 sm:px-6 lg:px-8">
                    <div className="h-full max-w-full min-h-full px-2 mx-auto sm:px-6 lg:px-8 ">
                        <div className=" lg:grid lg:grid-cols-12 lg:gap-x-0">
                            <div className="mt-10 text-left lg:col-start-1 lg:col-end-6 lg:row-start-1 lg:mt-9 xl:col-start-1">
                                {/* <h3 className="font-bold text-gray-900 text-md ">{profile?.name}</h3>
                                <p className="text-sm font-medium text-gray-500 ">{profile?.designation}</p>{" "}
                                <h4 className="pt-4">
                                    Monthly Total : <span className="font-bold">{getCountMonthly()} Hrs</span>
                                </h4> */}
                                <p className="pt-5 text-xs text-left text-gray-500">
                                    Time tracking rules in place for your timesheets. Based on the information you provided, here are the rules:
                                    <br />
                                    <br />
                                    <b>Working Day:</b> Employees are expected to record 8 hours for a standard working day. This is a common practice in many industries where an 8-hour workday is the standard.
                                    <br />
                                    <br />
                                    <b>Leaves:</b> When an employee takes a leave day, they should record 0 hours worked for that day. This means that leave days won't count towards the 8-hour working day requirement.
                                </p>
                                <p className="pt-5 text-xs text-left text-gray-500">
                                    <ul className="list-disc ">
                                        <li>Time Entry Deadlines: Employees may have a deadline for submitting their timesheets, ensuring timely payroll processing.</li>
                                        <li>Audit Trails constraints may include the retention of audit trails or revision history for timesheet entries for compliance or accountability.</li>
                                    </ul>
                                </p>
                                <br />
                                <p className="text-xs font-bold">
                                    <b className="text-sm font-bolder">Legends:</b>
                                    <ul className="list-disc">
                                        <li className="text-red-900">Red represents non-compliance or invalid data</li>
                                        <li className="text-green-900">Green represents compliance & valid data</li>
                                        <li className="bg-yellow-100">Yellow represents warning, since of non-compliance data or invalid entry</li>
                                        <li className="text-indigo-900">Blue represnts today's date</li>
                                    </ul>
                                </p>
                            </div>

                            <TimesheetCalendar uuid={uuid} />
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
