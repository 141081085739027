import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";
import Button from "../components/Button";
import Moment from "react-moment";
import { adjustRate } from "../api/finance-api";
import { useSnackbar } from "react-simple-snackbar";

const ChangeRateModal = ({ show, close, isLoading, proceed, _state }) => {
    const [name, setName] = useState("");

    const [state, setState] = useState(_state);

    const [openSnackbar, closeSnackbar] = useSnackbar();

    const updateNewRate = (_v: any) => {
        setState({ ...state, newRate: _v });
    };

    useEffect(() => {
        console.log(state);
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(state);

        adjustRate(state).then((r) => {
            console.log(r);
            openSnackbar("Refresh last results, for updated rates!");
            close();
            proceed({ name });
        });
    };

    return (
        <Transition.Root appear show={show} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={close}>
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <form onSubmit={handleSubmit} className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-300" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                                    <div className="flex flex-col w-full text-center-x justify-center-x items-center-x">
                                        {/* <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 m-0 mx-0 bg-red-100 rounded-full">
                                            <ExclamationCircleIcon className="w-6 h-6 text-red-600" aria-hidden="true" />
                                        </div> */}
                                        <Dialog.Title as="h3" className="mt-4 text-base font-semibold leading-6 text-gray-900">
                                            Change Rate
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Change Rate for <span className="font-semibold text-blue-950">{state?.name}</span> for period{" "}
                                                <span className="font-semibold text-blue-950">
                                                    <Moment format="MMM-YYYY">{state?.checkDate}</Moment>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="mt-3 sm:col-span-6">
                                            <input type="number" name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="New Rate" onChange={(e) => updateNewRate(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center w-full px-4 py-3 bg-gray-50 sm:flex sm:px-6">
                                    <Button className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-blue-600 rounded-md shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto" isLoading={isLoading} label={isLoading ? "Saving..." : "Save"} type="submit" />
                                    <Button className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-gray-400 rounded-md shadow-sm hover:bg-gray-500 sm:ml-3 sm:w-auto" isLoading={isLoading} label={isLoading ? "Cancelling..." : "Cancel"} type="button" onClick={close} />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </form>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

ChangeRateModal.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
    isLoading: PropTypes.bool,
    proceed: PropTypes.func,
};

ChangeRateModal.defaultProps = {
    show: false,
    close: () => {},
    isLoading: false,
    proceed: () => {},
};
export default ChangeRateModal;
