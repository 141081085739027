import React, { useEffect, useState } from "react";
import { listOfEmployeesValidPaystub } from "../api/consultants-api";
import Breadcrums from "../components/breadcrums";
import PaystubToolbar from "../blocks/paystub-toolbar";
import { Link } from "react-router-dom";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { saveBulkPayroll } from "../api/payroll-api";
import { useSnackbar } from "react-simple-snackbar";
import { Payroll } from "../model";
import moment from "moment";
import { nanoid } from "nanoid";
import Moment from "react-moment";
// import { format, toDate, parseISO } from "date-fns";

const breadcrumLinks = [
    { name: "Payroll", href: "/payroll", current: false },
    { name: "New Paystub", href: "/payroll/new", current: true },
];

export default function NewPayroll() {
    const [people, setPeople] = useState([]);

    const [dates, setDates] = useState({
        checkDate: moment(),
        startDate: moment(),
        endDate: moment(),
    });

    const { register, control, handleSubmit, reset, trigger, setError, setValue } = useForm<Payroll>();
    const { fields, append, remove } = useFieldArray({
        control,
        name: "consultants",
    });
    const [openSnackbar, closeSnackbar] = useSnackbar();

    useEffect(() => {
        listOfEmployeesValidPaystub({ activeStatus: 1 }).then((data) => {
            setPeople(data);
            console.log(data);
            setPeople(data);

            let payroll: Payroll["consultants"] = [];
            data.forEach((person, pIdx) => {
                console.log(person.unstructured);

                let definedPayStub = { hrs: 0, e_regular: 0, e_total: 0, t_federal: 0, t_ssn: 0, t_medicare: 0, t_gasit: 0, t_wa_ml: 0, t_wa_fl: 0, t_wa_ltc: 0, t_wa_wc: 0, t_total: 0, d_insurance: 0, d_dental: 0, d_vision: 0, d_401k: 0, d_irs: 0, d_total: 0, net_pay: 0 };
                if (person.unstructured) {
                    var pay = JSON.parse(person.unstructured);
                    definedPayStub = Object.assign({}, pay?.paystubAnnexure);
                    console.log(definedPayStub);
                }

                let setPayroll: Payroll["consultants"][0] = {
                    uuid: person.uuid,
                    name: person.name,
                    selected: true,

                    check_date: dates.checkDate.format("YYYY-MM-DD"),
                    _d_start: dates.startDate.format("YYYY-MM-DD"),
                    _d_end: dates.endDate.format("YYYY-MM-DD"),

                    hrs: 80, //FIXME
                    check_no: "Cash", //FIXME

                    payment_type: "Regular",

                    e_regular: definedPayStub ? definedPayStub?.e_regular : 0,
                    e_total: definedPayStub ? definedPayStub?.e_total : 0,

                    t_federal: definedPayStub ? definedPayStub?.t_federal : 0,
                    t_ssn: definedPayStub ? definedPayStub?.t_ssn : 0,
                    t_medicare: definedPayStub ? definedPayStub?.t_medicare : 0,

                    t_gasit: definedPayStub ? Number(definedPayStub?.t_gasit | 0) : 0,
                    t_wa_ml: definedPayStub ? Number(definedPayStub?.t_wa_ml | 0) : 0,
                    t_wa_fl: definedPayStub ? Number(definedPayStub?.t_wa_fl | 0) : 0,
                    t_wa_ltc: definedPayStub ? Number(definedPayStub?.t_wa_ltc | 0) : 0,
                    t_wa_wc: definedPayStub ? Number(definedPayStub?.t_wa_wc | 0) : 0,

                    t_total: definedPayStub ? definedPayStub?.t_total : 0,

                    d_insurance: definedPayStub ? definedPayStub?.d_insurance : 0,
                    d_dental: definedPayStub ? definedPayStub?.d_dental : 0,
                    d_vision: definedPayStub ? definedPayStub?.d_vision : 0,
                    d_401k: definedPayStub ? definedPayStub?.d_401k : 0,
                    d_irs: definedPayStub ? definedPayStub?.d_irs : 0,
                    d_total: definedPayStub ? definedPayStub?.d_total : 0,

                    net_pay: definedPayStub ? definedPayStub?.net_pay : 0,
                };

                payroll.push(setPayroll);
            });

            setValue("consultants", payroll);
        });
    }, []);

    const payrollToolbarHandler = (option) => {
        console.log("Handler clicked");
        console.log(option);

        if (option.value == 0) {
            setDates({ ...dates, checkDate: moment().endOf("month"), startDate: moment().date(1), endDate: moment().date(15) });
        } else if (option.value == 1) {
            setDates({ ...dates, checkDate: moment().add(1, "month").date(15), startDate: moment().date(16), endDate: moment().endOf("month") });
        } else if (option.value == -1) {
            setDates({ ...dates, checkDate: moment().date(15), startDate: moment().subtract(1, "month").date(16), endDate: moment().subtract(1, "month").endOf("month") });
        } else if (option.value == -2) {
            setDates({ ...dates, checkDate: moment().subtract(1, "month").endOf("month"), startDate: moment().subtract(1, "month").date(1), endDate: moment().subtract(1, "month").date(15) });
        } else if (option.value == -3) {
            setDates({ ...dates, checkDate: moment().subtract(1, "month").date(15), startDate: moment().subtract(2, "month").date(16), endDate: moment().subtract(2, "month").endOf("month") });
        } else if (option.value == -4) {
            setDates({ ...dates, checkDate: moment().subtract(2, "month").endOf("month"), startDate: moment().subtract(2, "month").date(1), endDate: moment().subtract(2, "month").date(15) });
        } else if (option.value == -5) {
            setDates({ ...dates, checkDate: moment().subtract(2, "month").date(15), startDate: moment().subtract(3, "month").date(16), endDate: moment().subtract(3, "month").endOf("month") });
        } else if (option.value == -6) {
            setDates({ ...dates, checkDate: moment().subtract(3, "month").endOf("month"), startDate: moment().subtract(3, "month").date(1), endDate: moment().subtract(3, "month").date(15) });
        }
    };

    const onSubmit: SubmitHandler<any> = async (data: any) => {
        console.log(data);

        data.consultants.forEach((e, eIdx) => {
            e.check_date = dates.checkDate.format("YYYY-MM-DD");
            e._d_start = dates.startDate.format("YYYY-MM-DD");
            e._d_end = dates.endDate.format("YYYY-MM-DD");
        });

        console.log(data.consultants);

        saveBulkPayroll(data.consultants).then((result: any) => {
            console.log(result);
            openSnackbar(result?.error ? result?.error.sqlMessage : "Done");
        });
    };

    return (
        <div>
            <Breadcrums breadcrumLinks={breadcrumLinks} module={"Payroll"} />

            <div className="px-4 py-6 sm:px-6 lg:px-8">
                <div className="mb-6 sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        {/* <h1 className="text-xl font-semibold text-gray-900">Users</h1> */}
                        <h1 className="text-2xl font-semibold text-gray-900">Paystub Details</h1>
                        <p className="mt-2 text-sm text-gray-700"></p>

                        <p className="mt-2 text-sm text-gray-700">
                            Check Date Range :<Moment format="YYYY/MM/DD">{dates.startDate}</Moment> to <Moment format="YYYY/MM/DD">{dates.endDate}</Moment>
                        </p>

                        <p className="mt-2 text-sm text-gray-700">
                            Check Date : <Moment format="YYYY/MM/DD">{dates.checkDate}</Moment>
                        </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
                </div>

                <PaystubToolbar handler={payrollToolbarHandler} />
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="px-4 py-0 sm:px-6 lg:px-8">
                    <div className="flex flex-col mt-0">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-0 align-middle md:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-500">
                                            <tr className="">
                                                <th className="bg-gray-100"> </th>
                                                {/* py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 */}
                                                <th scope="col" className="py-4 pl-4 pr-3 text-sm font-extrabold text-left text-gray-900 bg-gray-50 whitespace-nowrap sm:pl-6">
                                                    Employee
                                                </th>
                                                <th className="bg-gray-100">Hours & Earning</th>
                                                <th className="bg-gray-200">Taxes</th>
                                                <th className="bg-gray-300">Deductions</th>
                                                <th className="block w-[60px] bg-gray-500 text-white">Net Pay</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {fields.map((person: any, personIdx) => (
                                                <tr key={nanoid()} className={personIdx % 2 === 0 ? undefined : "bg-gray-0"}>
                                                    <td className="py-4 pl-4 pr-3 text-sm font-normal text-gray-900 whitespace-nowrap sm:pl-6">
                                                        <div className="flex items-center">
                                                            <input  {...register(`consultants.${personIdx}.selected`)} key={nanoid()} type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                           
                                                        </div>
                                                    </td>

                                                    <td className="py-4 pl-4 pr-3 text-sm font-normal text-gray-900 whitespace-nowrap sm:pl-6">{person.name}</td>

                                                    <td className="px-2 bg-gray-100">
                                                        <div className="grid gap-1 md:grid-cols-1">
                                                            <div>
                                                                <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                    <small>Hours</small>
                                                                </label>
                                                                <input type="text" {...register(`consultants.${personIdx}.hrs`)} className="block w-full p-1 text-sm text-gray-900 bg-white border border-gray-300 whitespace-nowrap" placeholder="" />
                                                            </div>
                                                            <div>
                                                                <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                    <small>Regular Earnings</small>
                                                                </label>
                                                                <input type="text" {...register(`consultants.${personIdx}.e_regular`)} className="block w-full p-1 text-sm text-gray-900 bg-white border border-gray-300 whitespace-nowrap" placeholder="" />
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td className="px-2 bg-gray-200">
                                                        <div className="grid gap-1 md:grid-cols-4">
                                                            <div>
                                                                <label htmlFor="large-input" className="w-full text-xs whitespace-nowrap">
                                                                    <small>Federal Income Tax</small>
                                                                </label>
                                                                <input type="text" {...register(`consultants.${personIdx}.t_federal`)} className="block w-full p-1 text-sm text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                            </div>
                                                            <div>
                                                                <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                    <small>Social Security</small>
                                                                </label>
                                                                <input type="text" {...register(`consultants.${personIdx}.t_ssn`)} className="block w-full p-1 text-sm text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                            </div>
                                                            <div>
                                                                <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                    <small>Medicare</small>
                                                                </label>
                                                                <input type="text" {...register(`consultants.${personIdx}.t_medicare`)} className="block w-full p-1 text-sm text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                            </div>
                                                            <div>
                                                                <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                    <small>GA SIT</small>
                                                                </label>
                                                                <input type="text" {...register(`consultants.${personIdx}.t_gasit`)} className="block w-full p-1 text-sm text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                            </div>
                                                            <div>
                                                                <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                    <small>WA ML</small>
                                                                </label>
                                                                <input type="text" {...register(`consultants.${personIdx}.t_wa_ml`)} className="block w-full p-1 text-sm text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                            </div>
                                                            <div>
                                                                <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                    <small>WA FL</small>
                                                                </label>
                                                                <input type="text" {...register(`consultants.${personIdx}.t_wa_fl`)} className="block w-full p-1 text-sm text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                            </div>

                                                            <div>
                                                                <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                    <small>WA LTC</small>
                                                                </label>
                                                                <input type="text" {...register(`consultants.${personIdx}.t_wa_ltc`)} className="block w-full p-1 text-sm text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                            </div>

                                                            <div>
                                                                <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                    <small>WA WC</small>
                                                                </label>
                                                                <input type="text" {...register(`consultants.${personIdx}.t_wa_wc`)} className="block w-full p-1 text-sm text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td className="px-2 bg-gray-300">
                                                        <div className="grid gap-1 md:grid-cols-3">
                                                            <div>
                                                                <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                    <small>Health Insurance</small>
                                                                </label>
                                                                <input type="text" {...register(`consultants.${personIdx}.d_insurance`)} className="block w-full p-1 text-sm text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                            </div>
                                                            <div>
                                                                <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                    <small>Dental Pre-Tax</small>
                                                                </label>
                                                                <input type="text" {...register(`consultants.${personIdx}.d_dental`)} className="block w-full p-1 text-sm text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                            </div>
                                                            <div>
                                                                <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                    Vision Pre-Tax
                                                                </label>
                                                                <input type="text" {...register(`consultants.${personIdx}.d_vision`)} className="block w-full p-1 text-sm text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                            </div>
                                                            <div>
                                                                <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                    401(k)
                                                                </label>
                                                                <input type="text" {...register(`consultants.${personIdx}.d_401k`)} className="block w-full p-1 text-sm text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                            </div>
                                                            <div>
                                                                <label htmlFor="large-input" className="text-xs whitespace-nowrap">
                                                                    ROTH
                                                                </label>
                                                                <input type="text" {...register(`consultants.${personIdx}.d_irs`)} className="block w-full p-1 text-sm text-gray-900 bg-white border border-gray-300" placeholder="" />
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td className="text-white bg-gray-500">
                                                        <p className="block w-full pl-1 text-xs font-bold">
                                                            {/* <label {...register(`consultants.${personIdx}.net_pay`)} /> */}
                                                            <input type="text" {...register(`consultants.${personIdx}.net_pay`)} disabled={true} className="block w-full p-1 text-sm text-white bg-transparent border-0 border-transparent" placeholder="" />
                                                        </p>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex justify-end px-4 py-0 pt-10 pb-10 sm:px-6 lg:px-8">
                    <Link to="/payroll">
                        <button type="button" className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Cancel
                        </button>
                    </Link>
                    <button type="submit" className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-blue-900 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
}
