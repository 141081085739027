import React, { useRef } from "react";
import { GroupingState, useReactTable, getPaginationRowModel, getFilteredRowModel, getCoreRowModel, getGroupedRowModel, getExpandedRowModel, ColumnDef, flexRender, FilterFn, SortingFn, sortingFns, ColumnFiltersState, getSortedRowModel, getFacetedRowModel, getFacetedUniqueValues, getFacetedMinMaxValues, Column, Table } from "@tanstack/react-table";
import { RankingInfo, rankItem, compareItems } from "@tanstack/match-sorter-utils";
import classNames from "classnames";
import { nanoid } from "nanoid";

export default function PortalTable(props: { table: Table<any>; handler: any }) {
    const tableRef = useRef(null);

    function expandState(row: any) {
        row.getToggleExpandedHandler();
        // props.handler(["month"], { name: false, pid: true });
    }

    return (
        <section>
            <div className="flex flex-col w-full mt-8">
                <div className="col-span-12">
                    <div className="overflow-auto lg:overflow-visible ">
                        <table ref={tableRef} className="min-w-full space-y-6 text-sm text-gray-400 divide-y divide-gray-300 border-separate-x border-spacing-0">
                            <thead className="font-bold text-black bg-gray-50">
                                {props.table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th key={header.id + nanoid()} colSpan={header.colSpan} className="p-3 px-5 py-3 text-center ">
                                                    {header.isPlaceholder ? null : (
                                                        <div>
                                                            <>
                                                                <div
                                                                    {...{
                                                                        className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                                                                        onClick: header.column.getToggleSortingHandler(),
                                                                    }}
                                                                >
                                                                    {header.column.getCanGroup() ? (
                                                                        // If the header can be grouped, let's add a toggle
                                                                        <button
                                                                            {...{
                                                                                onClick: header.column.getToggleGroupingHandler(),
                                                                                style: {
                                                                                    cursor: "pointer",
                                                                                },
                                                                            }}
                                                                        >
                                                                            {header.column.getIsGrouped() ? `🖖(${header.column.getGroupedIndex()}) ` : `✋`}
                                                                        </button>
                                                                    ) : null}{" "}
                                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                                    {{
                                                                        asc: " 🡩 ",
                                                                        desc: " 🡫 ",
                                                                    }[header.column.getIsSorted() as string] ?? null}
                                                                </div>
                                                                {header.column.getCanFilter() ? (
                                                                    <div>
                                                                        <Filter column={header.column} table={props.table} />
                                                                    </div>
                                                                ) : null}
                                                            </>
                                                        </div>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-300">
                                {props.table.getRowModel().rows.map((row) => {
                                    return (
                                        <tr key={row.id + nanoid()} className="px-5 py-5 text-sm border-b pl-4 pr-3 px-3 py-1.5 text-sm sm:pl-6 text-gray-900 hover:bg-gray-100">
                                            {row.getVisibleCells().map((cell) => {
                                                return (
                                                    <td
                                                        className={classNames("relative p-3 py-2 pl-3 pr-4 text-sm font-medium whitespace-nowrap sm:pr-6", cell.column.columnDef.meta?.cellClassName, cell.column.columnDef.meta?.className)}
                                                        {...{
                                                            key: cell.id,
                                                            style: {
                                                                // FIXME
                                                                // color: "black",
                                                                // background: cell.getIsGrouped() ? "#FFFFFF" : cell.getIsAggregated() ? "#FFFFFF" : cell.getIsPlaceholder() ? "#FFFFF" : "#FFFFFF",
                                                            },
                                                        }}
                                                    >
                                                        {cell.getIsGrouped() ? (
                                                            // If it's a grouped cell, add an expander and row count
                                                            <>
                                                                <button
                                                                    {...{
                                                                        onClick:
                                                                            // expandState(row),
                                                                            // props.handler(["month"], { name: false, pid: true });
                                                                            row.getToggleExpandedHandler(),
                                                                        style: {
                                                                            cursor: row.getCanExpand() ? "pointer" : "normal",
                                                                        },
                                                                    }}
                                                                >
                                                                    {row.getIsExpanded() ? "🡳" : "🡲"} {flexRender(cell.column.columnDef.cell, cell.getContext())} ({row.subRows.length})
                                                                </button>
                                                            </>
                                                        ) : cell.getIsAggregated() ? (
                                                            // If the cell is aggregated, use the Aggregated
                                                            // renderer for cell
                                                            flexRender(cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell, cell.getContext())
                                                        ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                                                            // Otherwise, just render the regular cell
                                                            flexRender(cell.column.columnDef.cell, cell.getContext())
                                                        )}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    );
}

function Filter({ column, table }: { column: Column<any, unknown>; table: Table<any> }) {
    const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

    const columnFilterValue = column.getFilterValue();

    const sortedUniqueValues = React.useMemo(() => (typeof firstValue === "number" ? [] : Array.from(column.getFacetedUniqueValues().keys()).sort()), [column.getFacetedUniqueValues()]);

    return typeof firstValue === "number" ? (
        <div>
            <div className="flex space-x-2">
                <DebouncedInput type="number" min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")} max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")} value={(columnFilterValue as [number, number])?.[0] ?? ""} onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])} placeholder={`Min ${column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ""}`} className="w-24 px-1 text-gray-300" />
                <DebouncedInput type="number" min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")} max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")} value={(columnFilterValue as [number, number])?.[1] ?? ""} onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])} placeholder={`Max ${column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ""}`} className="w-24 px-1 text-gray-300" />
            </div>
            <div className="h-1" />
        </div>
    ) : (
        <>
            <datalist id={column.id + "list"}>
                {sortedUniqueValues.slice(0, 5000).map((value: any) => (
                    <option value={value} key={value} />
                ))}
            </datalist>
            <DebouncedInput type="text" value={(columnFilterValue ?? "") as string} onChange={(value) => column.setFilterValue(value)} placeholder={`Search... (${column.getFacetedUniqueValues().size})`} className="w-auto px-1 text-black text-md" list={column.id + "list"} />
            <div className="h-1" />
        </>
    );
}

// A debounced input react component
function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: {
    value: string | number;
    onChange: (value: string | number) => void;
    debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
    const [value, setValue] = React.useState(initialValue);

    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value]);

    return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />;
}
