import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrums from "../components/breadcrums";
import FinanceCalendar from "../blocks/FinanceCalendar";
import { getFinanceAgregatedDetails, getFinanceDetails, saveFinanceRecord } from "../api/finance-api";
import moment from "moment";

import Currency from "react-currency-formatter";
import "@tanstack/react-table";

import { useReactTable, getCoreRowModel, getExpandedRowModel, ColumnDef, flexRender, Row, FilterFn, SortingFn, sortingFns } from "@tanstack/react-table";
import { compareItems, rankItem } from "@tanstack/match-sorter-utils";
import { InformationCircleIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import PageTitle from "../components/pageTitle";
import { paymentsCommands, sectionClass } from "../constants/common";
import Command from "../components/command";
import { paymentsBreadcrums } from "../constants/breadcrums";

type TableProps<TData> = {
    data: TData[];
    columns: ColumnDef<TData>[];
    renderSubComponent: (props: { row: Row<TData> }) => React.ReactElement;
    getRowCanExpand: (row: Row<TData>) => boolean;
};

// declare module "@tanstack/table-core" {
//     interface FilterFns {
//         myCustomFilter: FilterFn<unknown>;
//     }
// }

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
        itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
};

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
    let dir = 0;

    // Only sort by rank if the column has ranking information
    if (rowA.columnFiltersMeta[columnId]) {
        dir = compareItems(rowA.columnFiltersMeta[columnId]?.itemRank!, rowB.columnFiltersMeta[columnId]?.itemRank!);
    }

    // Provide an alphanumeric fallback for when the item ranks are equal
    return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

function Table({ data, columns, renderSubComponent, getRowCanExpand }: TableProps<any>): JSX.Element {
    const table = useReactTable<any>({
        data,
        columns,
        getRowCanExpand,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        filterFns: {
            fuzzy: fuzzyFilter,
            // myCustomFilter: undefined,
        },
    });

    return (
        <main>
            <div className="p-0">
                <div className="relative h-0 overflow-x-auto" />
                <table className="w-full text-sm text-left text-gray-500 rtl:text-right dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th key={header.id} colSpan={header.colSpan} className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                            {header.isPlaceholder ? null : <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => {
                            return (
                                <Fragment key={row.id}>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        {/* first row is a normal row */}
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                <td key={cell.id} className={classNames("px-6 py-4 text-sm font-medium text-gray-500 text-gray-900 whitespace-nowrap dark:text-white", cell.column.columnDef.meta?.cellClassName, cell.column.columnDef.meta?.className)}>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                    {row.getIsExpanded() && (
                                        <tr>
                                            {/* 2nd row is a custom 1 cell row */}
                                            <td colSpan={row.getVisibleCells().length}>{renderSubComponent({ row })}</td>
                                        </tr>
                                    )}
                                </Fragment>
                            );
                        })}
                    </tbody>
                </table>
                <div className="h-2" />
                <div className="flex items-center pb-3 pl-6 text-xs ">
                    {" "}
                    <InformationCircleIcon className="w-4 h-4 mr-1" /> {table.getRowModel().rows.length} Records
                </div>
            </div>
        </main>
    );
}

const renderSubComponent = ({ row }: { row: Row<any> }) => {
    return (
        <pre style={{ fontSize: "10px" }}>
            <code>{JSON.stringify(row.original, null, 2)}</code>
        </pre>
    );
};

export default function Payments() {
    const [events, setEvents] = useState<any>([]);

    const [records, setRecords] = useState<any>([]);

    const columns = React.useMemo<ColumnDef<any, any>[]>(
        () => [
            {
                accessorKey: "name",
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                meta: {},
            },
            {
                accessorKey: "rate",
                cell: (info) => <Currency quantity={info.getValue() || 0} currency="USD" />,
                enableGrouping: false,
                enableColumnFilter: false,
                meta: {},
            },
            {
                accessorKey: "hrs",
                cell: (info) => info.getValue() || 0,
                enableGrouping: false,
                enableColumnFilter: false,
                meta: {},
            },
            {
                accessorKey: "bill",
                cell: (info) => <Currency quantity={info.getValue() || 0} currency="USD" />,
                enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: true,
                columnVisibility: false,
                meta: {},
            },
            {
                accessorKey: "payment",
                cell: (info) => <Currency quantity={info.getValue() || 0} currency="USD" />,
                enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                columnVisibility: false,
                meta: {},
            },
            {
                accessorKey: "diff",
                cell: ({ row }) => <Currency quantity={row.original.bill - row.original.payment || 0} currency="USD" className={classNames("text-red-900")} />,
                enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                columnVisibility: false,
                meta: {
                    cellClassName: "",
                },
            },
            {
                header: "Actions",
                id: "actions",
                accessorKey: "actions",
                filterable: false,
                sortable: false,
                resizable: true,
                suppressSizeToFit: true,
                cell: ({ row }) => (
                    <>
                        {row.original.verified === 0 && (
                            <button
                                type="button"
                                className="inline-flex items-center justify-center px-4 py-1 text-sm font-medium text-black bg-gray-300 border border-transparent rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
                                onClick={() => {
                                    console.log(row);
                                    // alert("Verify " + row);

                                    var financeObj = row.original;
                                    financeObj.verified = true;
                                    financeObj._ts_verify = new Date();
                                    saveFinanceRecord(financeObj).then((r) => {
                                        row.original.verified = 1; //FIXME
                                        console.log(r);
                                        // flexRender(Link, { id: row. });
                                    });
                                }}
                            >
                                Verify
                            </button>
                        )}
                        {row.original.verified === 1 && <span className="text-green-600">Verified</span>}

                        {row.original.bill !== row.original.payment && (
                            <button
                                type="button"
                                className="inline-flex items-center justify-center px-4 py-1 ml-2 text-sm font-medium text-black bg-gray-300 border border-transparent rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
                                onClick={() => {
                                    console.log(row);
                                    // alert("Verify " + row);

                                    var financeObj = row.original;
                                    financeObj.payment = financeObj.bill;
                                    financeObj.paid = 1;
                                    financeObj._ts_payment = new Date();
                                    saveFinanceRecord(financeObj).then((r) => {
                                        console.log(r); // FIXME
                                    });
                                }}
                            >
                                Mark as Paid
                            </button>
                        )}
                    </>
                ),
                // cell: ({e}) => <Link to={/admin/product/update/${e.id}}> Update </span> </Link>,
            },
        ],
        []
    );

    useEffect(() => {
        // Use Finance for given month - FIXME
        const result: any = [];
        getFinanceAgregatedDetails({}).then((d) => {
            d.forEach((element: any) => {
                result.push({
                    title: element["sum(hrs)"] + " Hrs (" + element["count(_d)"] + ")",
                    date: setDate(element._d),
                    color: element["sum(bill)"] > element["sum(payment)"] ? "red" : "green",
                    textEscape: false,
                });
            });
            setEvents(result);
        });
    }, []);

    useEffect(() => {
        // console.log(events);
    }, [events]);

    const setDate = (curDate: any) => {
        let utcDate = new Date(curDate);
        return new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate());
    };

    const handleCalendarDateClick = (arg) => {
        console.log(arg);
        let reqDate = arg.event.start;
        getFinanceDetails({
            date: moment(reqDate).format("YYYY-MM-DD"),
        }).then((r) => {
            setRecords(r);
        });
    };

    return (
        <main>
            <Breadcrums breadcrumLinks={paymentsBreadcrums} commands={paymentsCommands} module="Payment" groups={["Actions", "Statement", "Forecast"]} />
            <PageTitle title="Payments" preSummary="" summary="">
                <Link to="/payments/invoice">
                    <button type="button" className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-black bg-gray-300 border border-transparent rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto">
                        Generate Invoice
                    </button>
                </Link>{" "}
                {/* <Link to="#">
                    <button type="button" className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-black bg-gray-300 border border-transparent rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto">
                        Bulk Import
                    </button>
                </Link> */}
            </PageTitle>

            <div className={classNames(sectionClass)}>
                <FinanceCalendar events={events} onDateClick={handleCalendarDateClick} />

                {records.length > 0 && (
                    <>
                        <section aria-labelledby="billing-history-heading" className="pt-5">
                            <div className="pt-6 bg-white shadow sm:rounded-md sm:overflow-hidden">
                                <div className="px-4 sm:px-6">
                                    <h2 id="billing-history-heading" className="text-lg font-medium leading-3 text-gray-900">
                                        Efforts-Billing-Payment Records
                                    </h2>
                                </div>
                                <div className="flex flex-col mt-6">
                                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                            <div className="overflow-hidden border-t border-gray-200">
                                                <Table data={records} columns={columns} getRowCanExpand={() => false} renderSubComponent={renderSubComponent} />

                                                <table className="min-w-full divide-y divide-gray-200">
                                                    {/* <thead className="bg-gray-50">
                                                        <tr>
                                                            <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                                                Date
                                                            </th>
                                                            <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                                                Description
                                                            </th>
                                                            <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                                                Amount
                                                            </th>
                                                            {/*
              `relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
            * /}
                                                            <th scope="col" className="relative px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                                                <span className="sr-only">View receipt</span>
                                                            </th>
                                                        </tr>
                                                    </thead> */}
                                                    <tbody className="bg-white divide-y divide-gray-200">
                                                        {/* {payments.map((payment) => (
                                                            <tr key={payment.id}>
                                                                <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">
                                                                    <time dateTime={payment.datetime}>{payment.date}</time>
                                                                </td>
                                                                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{payment.description}</td>
                                                                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{payment.amount}</td>
                                                                <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                                                                    <a href={payment.href} className="text-orange-600 hover:text-orange-900">
                                                                        View receipt
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        ))} */}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div></div>
                    </>
                )}
            </div>
        </main>
    );
}
