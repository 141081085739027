import { rankItem } from "@tanstack/match-sorter-utils";
import { Column, ColumnFiltersState, FilterFn, GroupingState, Table, flexRender, getCoreRowModel, getExpandedRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getGroupedRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import classNames from "classnames";
import moment from "moment";
import { nanoid } from "nanoid";
import React, { useRef } from "react";
import { downloadExcel } from "react-export-table-to-excel";

import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";

import { JSDOM } from "jsdom";
import DOMPurify from "dompurify";
// import ModalWithAbort from "./ModalWithAbort";
import ModalWithForm from "./ModalWithForm";

interface TanstackTableProps {
    data: any;
    columns: any;
    downloadBtn?: any;
}

const defaultProps: TanstackTableProps = {
    data: [],
    columns: [],
    downloadBtn: false,
};

export default function TanstackTable(props: TanstackTableProps) {
    // console.log(props.columns);
    // console.log(props.data);

    const [grouping, setGrouping] = React.useState<GroupingState>([]);
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = React.useState("");
    // const [isConsultatnVisible, setisConsultatnVisible] = React.useState(false);
    const [columnVisibilityState, setColumnVisibilityState] = React.useState({});

    // const [data, setData] = React.useState([]);
    const [data] = React.useState(() => [...props.data]);

    const [columns] = React.useState(() => [...props.columns]);
    const [show, setShow] = React.useState(false);
    const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
        // Rank the item
        const itemRank = rankItem(row.getValue(columnId), value);

        // Store the itemRank info
        addMeta({
            itemRank,
        });

        // Return if the item should be filtered in/out
        return itemRank.passed;
    };

    const table = useReactTable({
        data,
        columns,

        filterFns: {
            fuzzy: fuzzyFilter,
        },
        initialState: {
            // columnVisibility: {
            //     consultant: false,
            // },
            // hiddenColumns: columns.filter((col) => col.show === false).map((col) => col.accessor),
            // hiddenColumns: ["accesorName"],
            // hiddenColumns: columns.filter((column) => !column?.isVisible).map((column) => column.accessor),
        },
        state: {
            columnVisibility: columnVisibilityState,
            grouping,
            columnFilters,
            globalFilter,
        },
        onGroupingChange: setGrouping,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        // getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        // debugTable: true,
        // debugHeaders: true,
        // debugColumns: true,
    });

    const tableRef = useRef(null);

    const header = columns.map((e) => {
        return e.header;
    });

    const body = [
        ["Edison", "Padilla", 14],
        ["Cheila", "Rodrigez", 56],
    ];

    const body2 = [
        { firstname: "Edison", lastname: "Padilla", age: 14 },
        { firstname: "Cheila", lastname: "Rodrigez", age: 56 },
    ];

    function handleDownloadExcel(event): void {
        downloadExcel({
            fileName: "data-" + moment(),
            sheet: "Data",
            tablePayload: {
                header: header,
                // accept two different data structures
                body: props.data.map(function (sub) {
                    let temp: any = {};
                    props.columns.forEach((element, index) => {
                        if (element["header"] !== "") {
                            temp[element["header"]] = sub[element.accessorKey];
                        }
                    });

                    return temp;
                }),
            },
        });
    }
    function handleModal() {
        setShow(true);
    }
    function closeModal() {
        setShow(false);
    }

    function headerEscape(header): any {
        return flexRender(header.column.columnDef.header, header.getContext());
    }

    const htmlFrom = (htmlString) => {
        const cleanHtmlString = DOMPurify.sanitize(htmlString, { USE_PROFILES: { html: true, ALLOWED_TAGS: [], ADD_TAGS: ["style", "class", "className"] } });
        const html = parse(cleanHtmlString);
        return html;
    };

    return (
        <section>
            {/* px-8 py-4 my-2 text-center text-white bg-indigo-600 border rounded sm:w-full lg:w-auto md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50 */}

            {props.downloadBtn && (
                <button className="inline-flex items-center justify-center gap-2 px-3 py-2 mt-5 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800" onClick={handleDownloadExcel}>
                    Download Excel
                    <img src="/excel.svg"></img>
                </button>
            )}
            {/* <button className="px-8 py-4 my-2 text-center text-white bg-indigo-600 border rounded sm:w-full lg:w-auto md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50" onClick={handleModal}>Abort Modal</button> */}
            {/* {show && <ModalWithForm close={closeModal} show={show} ></ModalWithForm>} */}
            <div className="flex flex-col w-full mt-8">
                <div className="col-span-12">
                    <div className="overflow-auto lg:overflow-visible ">
                        <table ref={tableRef} className="min-w-full space-y-6 text-sm text-gray-400 divide-y divide-gray-300 border-separate-x border-spacing-0">
                            <thead className="font-bold text-black bg-gray-50">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id + "-" + nanoid()}>
                                        {headerGroup.headers.map((header) => {
                                            // _header.push("");
                                            return (
                                                <th key={header.id + nanoid()} colSpan={header.colSpan} className={classNames("p-3 px-5 py-3 text-left ", header.column.columnDef.meta?.cellClassName, header.column.columnDef.meta?.className)}>
                                                    {header.isPlaceholder ? null : (
                                                        <div>
                                                            <>
                                                                <div
                                                                    {...{
                                                                        className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                                                                        onClick: header.column.getToggleSortingHandler(),
                                                                    }}
                                                                >
                                                                    {header.column.getCanGroup() ? (
                                                                        // If the header can be grouped, let's add a toggle
                                                                        <button
                                                                            {...{
                                                                                onClick: header.column.getToggleGroupingHandler(),
                                                                                style: {
                                                                                    cursor: "pointer",
                                                                                },
                                                                            }}
                                                                        >
                                                                            {header.column.getIsGrouped() ? `🖖(${header.column.getGroupedIndex()}) ` : `✋`}
                                                                        </button>
                                                                    ) : null}{" "}
                                                                    {/* {parse(flexRender(header.column.columnDef.header, header.getContext()) as string)} */}
                                                                    {/* {htmlFrom(headerEscape(header))} */}
                                                                    {ReactHtmlParser(headerEscape(header))}
                                                                    {/* <div suppressHydrationWarning dangerouslySetInnerHTML={{ __html: headerEscape(header) }}></div> */}
                                                                    {{
                                                                        asc: " 🡩 ",
                                                                        desc: " 🡫 ",
                                                                    }[header.column.getIsSorted() as string] ?? null}
                                                                </div>
                                                                {header.column.getCanFilter() ? (
                                                                    <div>
                                                                        <Filter column={header.column} table={table} />
                                                                    </div>
                                                                ) : null}
                                                            </>
                                                        </div>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            {props.data.length > 0 && (
                                <>
                                    <tbody className="bg-white divide-y divide-gray-300">
                                        {table.getRowModel().rows.map((row) => {
                                            return (
                                                <tr key={row.id + nanoid()} className="px-5 py-5 text-sm border-b pl-4 pr-3 px-3 py-1.5 text-sm sm:pl-6 text-gray-900 hover:bg-gray-100">
                                                    {row.getVisibleCells().map((cell) => {
                                                        return (
                                                            <td
                                                                className={classNames("p-3 py-2 pl-3 pr-4 text-sm font-medium whitespace-nowrap sm:pr-6", cell.column.columnDef.meta?.cellClassName, cell.column.columnDef.meta?.className)}
                                                                {...{
                                                                    key: cell.id + "-" + nanoid(),
                                                                    style: {
                                                                        // FIXME
                                                                        // color: "black",
                                                                        // background: cell.getIsGrouped() ? "#FFFFFF" : cell.getIsAggregated() ? "#FFFFFF" : cell.getIsPlaceholder() ? "#FFFFF" : "#FFFFFF",
                                                                    },
                                                                }}
                                                            >
                                                                {cell.getIsGrouped() ? (
                                                                    // If it's a grouped cell, add an expander and row count
                                                                    <>
                                                                        <button
                                                                            {...{
                                                                                onClick:
                                                                                    // expandState(row),
                                                                                    // props.handler(["month"], { name: false, pid: true });
                                                                                    row.getToggleExpandedHandler(),
                                                                                style: {
                                                                                    cursor: row.getCanExpand() ? "pointer" : "normal",
                                                                                },
                                                                            }}
                                                                        >
                                                                            {row.getIsExpanded() ? "🡳" : "🡲"} {flexRender(cell.column.columnDef.cell, cell.getContext())} ({row.subRows.length})
                                                                        </button>
                                                                    </>
                                                                ) : cell.getIsAggregated() ? (
                                                                    // If the cell is aggregated, use the Aggregated
                                                                    // renderer for cell
                                                                    flexRender(cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell, cell.getContext())
                                                                ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                                                                    // Otherwise, just render the regular cell
                                                                    flexRender(cell.column.columnDef.cell, cell.getContext())
                                                                )}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                    <tfoot className="font-bold text-black bg-gray-100">
                                        {table.getFooterGroups().map((footerGroup) => (
                                            <tr key={footerGroup.id + "-" + nanoid()}>
                                                {footerGroup.headers.map((footer) => {
                                                    return (
                                                        <th key={footer.id + nanoid()} colSpan={footer.colSpan} className={classNames("p-3 px-5 py-3 text-left text-sm text-gray-700", footer.column.columnDef.meta?.cellClassName, footer.column.columnDef.meta?.className)}>
                                                            {footer.isPlaceholder ? null : (
                                                                <div>
                                                                    <>{flexRender(footer.column.columnDef.footer, footer.getContext())}</>
                                                                </div>
                                                            )}
                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                        ))}
                                    </tfoot>
                                </>
                            )}
                        </table>
                    </div>
                </div>
            </div>
        </section>
    );
}

function Filter({ column, table }: { column: Column<any, unknown>; table: Table<any> }) {
    const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

    const columnFilterValue = column.getFilterValue();

    const sortedUniqueValues = React.useMemo(() => (typeof firstValue === "number" ? [] : Array.from(column.getFacetedUniqueValues().keys()).sort()), [column.getFacetedUniqueValues()]);

    return typeof firstValue === "number" ? (
        <div>
            <div className="flex space-x-2">
                <DebouncedInput type="number" min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")} max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")} value={(columnFilterValue as [number, number])?.[0] ?? ""} onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])} placeholder={`Min ${column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ""}`} className="w-24 px-1 text-gray-300" />
                <DebouncedInput type="number" min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")} max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")} value={(columnFilterValue as [number, number])?.[1] ?? ""} onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])} placeholder={`Max ${column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ""}`} className="w-24 px-1 text-gray-300" />
            </div>
            <div className="h-1" />
        </div>
    ) : (
        <>
            <datalist id={column.id + "list"}>
                {sortedUniqueValues.slice(0, 5000).map((value: any) => (
                    <option value={value} key={value} />
                ))}
            </datalist>
            <DebouncedInput type="text" value={(columnFilterValue ?? "") as string} onChange={(value) => column.setFilterValue(value)} placeholder={`Search... (${column.getFacetedUniqueValues().size})`} className="w-auto px-1 text-black text-md" list={column.id + "list"} />
            <div className="h-1" />
        </>
    );
}

// A debounced input react component
function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: {
    value: string | number;
    onChange: (value: string | number) => void;
    debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
    const [value, setValue] = React.useState(initialValue);

    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value]);

    return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />;
}
