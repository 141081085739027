import React from "react";

import { useQuery } from "convex/react";
import { api } from "../convex/_generated/api";
import TaskTable from "../blocks/taskTable";
import TaskToolbar from "../blocks/taskToolbar";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export default function Tasks() {
    const tasks = useQuery(api.tasks.get);

    return (
        <main className="flex-1 py-6">
            <div className="px-4 py-4 border-b border-gray-200 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
                <div className="flex-1 min-w-0">
                    <h1 className="text-2xl font-semibold text-gray-900">Tasks</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        <b>Efficient Task Management</b> - Streamlining Individual Responsibilities for Enhanced Productivity
                    </p>
                </div>
                <div className="flex mt-4 sm:mt-0 sm:ml-4">
                    <button type="button" className="inline-flex items-center order-1 px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:order-0 sm:ml-0">
                        Insights
                    </button>
                </div>
            </div>

            <section className="w-full px-4 py-5 sm:p-6">
                {/* <ProjectList /> */}
                <TaskToolbar />
                <TaskTable data={tasks ?? []} />
            </section>
        </main>
    );
}
