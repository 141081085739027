import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useSnackbar } from "react-simple-snackbar";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function TimesheetFilters(props) {
    const { register, handleSubmit, setValue } = useForm();

    const [openSnackbar, closeSnackbar] = useSnackbar();

    const [filters, setFilters] = useState({
        checkDate: new Date(),
    });

    const onSubmit = (data, e) => {};

    function applyFilters(filters): any {
        props.setFilteredCheckDate(filters.checkDate);
        props.applyFilters(filters);
    }

    return (
        <div className="pt-2 space-y-6">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="mt-5 md:mt-0 md:col-span-2">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="country" className="block text-sm text-gray-700 font-sm">
                                        Month-Year
                                    </label>
                                    <DatePicker dateFormat="MM/yyyy" showMonthYearPicker className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" selected={filters.checkDate} onChange={(date) => setFilters({ ...filters, checkDate: date })} />
                                </div>

                                <div className="pt-6">
                                    <button type="button" onClick={(e) => applyFilters(filters)} className="px-4 py-2 text-sm font-medium text-white bg-blue-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0 hover:bg-blue-800 focus:ring-blue-300">
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
