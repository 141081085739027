export async function getDashboardFinanceStats() {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/finance/aggregate?_fields=bill,payment");
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function getDashboardEmployeesStats() {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/consultant/distinct?_where=(active,eq,1)");
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function getProjectCountStats() {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/consultant/distinct?_fields=project");
        return await response.json();
    } catch (error) {
        return [];
    }
}
