import React, { useEffect } from "react";
import HeroSection from "./blocks/hero";
import HeroSections from "./blocks/heroSections";

import { useCookies } from "react-cookie";
import Dashboard from "./pages/dashboard";

import { useAuth0 } from "@auth0/auth0-react";

export default function Landing() {
    return (
        <>
            <HeroSection /> <HeroSections />
        </>
    );
}
