import React, { useEffect } from "react";
import { GroupingState, useReactTable, getPaginationRowModel, getFilteredRowModel, getCoreRowModel, getGroupedRowModel, getExpandedRowModel, ColumnDef, flexRender, FilterFn, SortingFn, sortingFns, ColumnFiltersState, getSortedRowModel, getFacetedRowModel, getFacetedUniqueValues, getFacetedMinMaxValues, Column, Table, RowModel } from "@tanstack/react-table";

import { RankingInfo, rankItem, compareItems } from "@tanstack/match-sorter-utils";
// import financeData from "../financeDummy.json";
import PortalTable from "../../components/portalTable";
import FinanceReportToolbar from "../../blocks/financeReportToolbar";
import Breadcrums from "../../components/breadcrums";
import { Link } from "react-router-dom";
import { getFinanceReportData } from "../../api/finance-api";
import PageTitle from "../../components/pageTitle";
import { FinanceRecord } from "../../model";

const breadcrumLinks = [
    { name: "Reports", href: "/reports", current: true },
    { name: "Finance Report", href: "#", current: true },
];

declare module "@tanstack/table-core" {
    interface FilterFns {
        fuzzy: FilterFn<unknown>;
    }
    interface FilterMeta {
        itemRank: RankingInfo;
    }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
        itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
};

export default function FinanceReport() {
    const [data, setData] = React.useState([]);

    const [grouping, setGrouping] = React.useState<GroupingState>([]);

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = React.useState("");
    const [isConsultatnVisible, setisConsultatnVisible] = React.useState(false);

    const [columnVisibilityState, setColumnVisibilityState] = React.useState({});

    const columns = React.useMemo<ColumnDef<FinanceRecord, any>[]>(
        () => [
            {
                accessorKey: "month",
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                // enableGrouping: false,
                // footer: (props) => props.column.id,
            },
            {
                accessorKey: "paid",
                cell: (info) => info.getValue(),
                enableGrouping: false,
                enableColumnFilter: false,
                // footer: (props) => props.column.id,
            },
            {
                accessorKey: "bill",
                cell: (info) => info.getValue(),
                enableGrouping: false,
                enableColumnFilter: false,
                // footer: (props) => props.column.id,
            },
            {
                accessorKey: "name",
                cell: (info) => info.getValue(),
                enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: true,
                // show: false,
                // hide: true,
                columnVisibility: false,

                // enablePinning: true,
                // footer: (props) => props.column.id,
            },
            {
                accessorKey: "pid",
                cell: (info) => info.getValue(),
                enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                // show: false,
                // hide: true,
                columnVisibility: false,

                // enablePinning: true,
                // footer: (props) => props.column.id,
            },
            // {
            //     accessorFn: (row) => row.month,
            //     id: "lastName",
            //     cell: (info) => info.getValue(),
            //     header: () => <span>Last Name</span>,
            //     // footer: (props) => props.column.id,
            // },
            // {
            //     accessorFn: (row) => `${row.firstName} ${row.lastName}`,
            //     id: "fullName",
            //     header: "Full Name",
            //     cell: (info) => info.getValue(),
            //     footer: (props) => props.column.id,
            //     filterFn: "fuzzy",
            //     sortingFn: fuzzySort,
            // },
            // {
            //     accessorKey: "invoice",
            //     header: () => "Age",
            //     // footer: (props) => props.column.id,
            // },
            // {
            //     accessorKey: "payment",
            //     header: () => <span>Visits</span>,
            //     footer: (props) => props.column.id,
            // },
            // {
            //     accessorKey: "status",
            //     header: "Status",
            //     footer: (props) => props.column.id,
            // },
            // {
            //     accessorKey: "progress",
            //     header: "Profile Progress",
            //     footer: (props) => props.column.id,
            // },
        ],
        []
    );

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        initialState: {
            // columnVisibility: {
            //     consultant: false,
            // },
            // hiddenColumns: columns.filter((col) => col.show === false).map((col) => col.accessor),
            // hiddenColumns: ["accesorName"],
            // hiddenColumns: columns.filter((column) => !column?.isVisible).map((column) => column.accessor),
        },
        state: {
            columnVisibility: columnVisibilityState,
            grouping,
            columnFilters,
            globalFilter,
        },
        onGroupingChange: setGrouping,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        // getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        // debugTable: true,
        // debugHeaders: true,
        // debugColumns: true,
    });

    // https://github.com/TanStack/table/discussions/1971
    // const { s } = table;

    useEffect(() => {
        getFinanceReportData({
            checkDate: new Date(),
            employee: "",
        }).then((data) => {
            setData(data);
        });
    }, []);

    const data1 = (groupCol: string[], visibility: any) => {
        // if (groupCol.includes("consultant")) {
        //     columns.forEach((e: any) => {
        //         if (e.accessorKey === groupCol) {
        //             e.columnVisibility = false;
        //             setisConsultatnVisible(true);
        //         }
        //         e.enableHiding = true;
        //     });
        // }

        // columns.forEach((e: any) => {
        //     if (Object.keys(visibility).includes(e.accessorKey)) {
        //         e.columnVisibility = false;
        //         // setisConsultatnVisible(false);
        //     }
        //     e.enableHiding = true;
        // });

        // table.getState().columnVisibility = visibility;

        console.log(visibility);
        table.setColumnVisibility(visibility);

        setColumnVisibilityState(visibility);
        setGrouping(groupCol);
    };
    // useEffect(() => {
    //     // set hidden column
    //     setHiddenColumns(columns.filter((column) => !column?.isVisible).map((column) => column.accessor));
    // }, [setHiddenColumns, columns]);

    return (
        <main>
            <Breadcrums breadcrumLinks={breadcrumLinks} module={"Report"} />

            <PageTitle title="Finance Report" preSummary="Comprehensive Finance Report" summary="Analyzing Account Receivables, Payments, Settlements, and Dues for Strategic Insights.">
                <Link to="/employees/new">
                    <button type="button" className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-900 border border-transparent rounded-md shadow-sm hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto">
                        Refresh
                    </button>
                </Link>
            </PageTitle>

            <div className="px-4 pt-5 mx-auto sm:px-6 lg:px-8">
                <FinanceReportToolbar data1={data1} />
                <PortalTable table={table} handler={data1} />
            </div>
        </main>
    );
}
