import React from "react";
import { useFormContext } from "react-hook-form";

interface TotalCurrencyFieldProps {
    label?: string;
    attribute: string;
    disabled?: boolean;
    labelClass?: string;
    inputClass?: string;
}

const defaultProps: TotalCurrencyFieldProps = {
    label: "Mr",
    disabled: false,
    attribute: "",
};

TotalCurrencyField.defaultProps = defaultProps;

export default function TotalCurrencyField(props: TotalCurrencyFieldProps) {
    const { register } = useFormContext();

    return (
        <>
            <div className="flex flex-wrap w-full">
                <div className="w-full">
                    <div className="grid grid-cols-4 gap-3 md:grid-cols-2">
                        <div className="flex items-center">
                            <label htmlFor={props.attribute} className={"block text-sm text-gray-700 " + props.labelClass}>
                                {props.label}
                            </label>
                        </div>
                        <div className="flex items-center col-span-1">
                            <div className="relative mt-1 rounded-md shadow-none">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <span className="text-gray-500 sm:text-sm">$</span>
                                </div>
                                <input
                                    type="text"
                                    disabled={props.disabled}
                                    {...register(props.attribute, {
                                        // onChange: (e) => console.log(e),
                                        // onBlur: (e) => {
                                        //     // console.log(e.target.value);
                                        //     props.onChangeHandler();
                                        // },
                                    })}
                                    name={props.attribute}
                                    id={props.attribute}
                                    className={"block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm " + props.inputClass}
                                    placeholder="0.00"
                                    aria-describedby={props.attribute}
                                    // onChange={props.onChangeHandler}
                                />
                                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                    <span className="text-gray-500 sm:text-sm">USD</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
