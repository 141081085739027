import { useEffect, useState } from "react";
import { Qualification } from "../model";
import { nanoid } from "nanoid";
import { getConsultantDetails } from "../api/consultants-api";

export default function QualificationDetails(props: { consultantDetails: any }) {
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (props.consultantDetails) {
            setItems(props.consultantDetails.filter((q) => q.type == "Qualifications"));
            // console.warn(props.consultantDetails);
        }
    }, []);

    return (
        <div className="pb-16 space-y-6">
            <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-1 md:gap-6">
                    {/* <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Qualification Information</h3>
                        <p className="mt-1 text-sm text-gray-500"></p>
                    </div> */}
                    <div className=" md:mt-0 md:col-span-2">
                        <div className="overflow-hidden bg-white sm:rounded-lg">
                            <ul role="list" className="border-gray-200 divide-y divide-gray-200 ">
                                {items.map((item: Qualification) => (
                                    <li key={nanoid()} className="flex py-4">
                                        {/* <img className="w-10 h-10 rounded-full" src={person.image} alt="" /> */}
                                        <div className="ml-3">
                                            <p className="flex text-xl font-medium text-gray-900">
                                                {item.course}{" "}
                                                {item.verified == 1 && (
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 pt-2 ml-0 text-green-700">
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M12.516 2.17a.75.75 0 00-1.032 0 11.209 11.209 0 01-7.877 3.08.75.75 0 00-.722.515A12.74 12.74 0 002.25 9.75c0 5.942 4.064 10.933 9.563 12.348a.749.749 0 00.374 0c5.499-1.415 9.563-6.406 9.563-12.348 0-1.39-.223-2.73-.635-3.985a.75.75 0 00-.722-.516l-.143.001c-2.996 0-5.717-1.17-7.734-3.08zm3.094 8.016a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                                            clip-rule="evenodd"
                                                        />
                                                    </svg>
                                                )}
                                            </p>
                                            <p className="text-sm font-medium text-gray-900">{item.college}</p>
                                            <p className="text-sm text-gray-500">
                                                {item.university}, {item.year}
                                            </p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            {items.length == 0 && <>No qualifications recorded.</>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
