import moment from "moment";

export async function addUpdateConsultant(consultantData) {
    console.log(consultantData);

    // FIXME
    consultantData.start_dt = moment(consultantData.start_dt).format("YYYY-MM-DD HH:mm:ss");
    consultantData.end_dt = moment(consultantData.end_dt).format("YYYY-MM-DD HH:mm:ss");

    const rawResponse = await fetch(process.env.REACT_APP_API_URL + "/api/consultant", {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(consultantData),
    });
    const content = await rawResponse.json();
}

export async function listOfEmployees() {
    try {
        // const response = await fetch(process.env.REACT_APP_API_URL + "/api/consultant?_where=(active,eq,1)&_sort=name");
        // return await response.json();

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
                method: "post",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    query: "SELECT * FROM consultant where active = 1 order by name;",
                    params: ["consultant"],
                }),
            });
            return await response.json();
        } catch (error) {
            return [];
        }


    } catch (error) {
        return [];
    }
}

export async function listIkatEmployees() {
    try {
        // const response = await fetch(process.env.REACT_APP_API_URL + "/api/consultant?_where=(active,eq,1)~and(project,eq,IKAT)&_sort=name");
        // return await response.json();

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
                method: "post",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    query: "SELECT * FROM consultant where active = 1 and project = 'IKAT' order by name;",
                    params: ["consultant"],
                }),
            });
            return await response.json();
        } catch (error) {
            return [];
        }


        
    } catch (error) {
        return [];
    }
}

export async function listOfEmployeesValidPaystub(filters: any) {
    let _q = "select * from consultant where 1=1 ";

    if (filters.activeStatus > 0) {
        _q += "and active = " + filters.activeStatus + " ";
    }

    _q += "and unstructured is not null and entity = 'MTINC'  ";

    _q += " order by name ";
    console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["consultant"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function getFilteredEmployees(filters: any) {
    console.log(filters);

    let _q = "select * from consultant where 1=1 ";
    if (filters.activeStatus >= 0) {
        _q += "and active = " + filters.activeStatus + "";
    }
    _q += " order by name ";
    console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["consultant"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export function getName(list: any, uuid: any) {
    if (list.length > 0) {
        return list.filter((v) => v.uuid === uuid)[0].name;
    } else {
        return uuid;
    }
}

export function getEmployeeRecord(list: any, uuid: any) {
    if (list.length > 0) {
        return list.filter((v) => v.uuid === uuid)[0];
    } else {
        return uuid;
    }
}

export async function getProfile(uuid: any) {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/consultant/" + uuid);
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function getProfileByUUID(uuid: any) {
    console.log(uuid);
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: "SELECT * from consultant where  uuid = '" + uuid + "'",
                params: ["consultant"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function getConsultantDetails(uuid: any) {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/consultant_details?_where=(uuid,eq," + uuid + ")~and(active,eq,1)&_sort=type,sub_type");
        return await response.json();
    } catch (error) {
        return [];
    }
}
