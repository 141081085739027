import React, { useEffect, useState } from "react";
import Breadcrums from "../../../components/breadcrums";
import { ColumnDef, ColumnFiltersState, FilterFn, GroupingState, getCoreRowModel, getExpandedRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getGroupedRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { getFinanceReportData, getFinanceReportDataByMonth } from "../../../api/finance-api";
import { Link } from "react-router-dom";
import PortalTable from "../../../components/portalTable";
import { rankItem } from "@tanstack/match-sorter-utils";
import CommonFilters from "../../../blocks/commonFilters";
import { useApi } from "../../../api/data-hook";
import { useSnackbar } from "react-simple-snackbar";
import { listOfEmployees } from "../../../api/consultants-api";

import Currency from "react-currency-formatter";
import PageTitle from "../../../components/pageTitle";
import classNames from "classnames";
import { paymentsCommands, sectionClass } from "../../../constants/common";
import { monthlyAccountRecievableBreadcrums } from "../../../constants/breadcrums";
import YearFilter from "../../../blocks/yearFilters";
import TanstackTable from "../../../components/TanstackTable";
import { FinanceRecord } from "../../../model";
import { NumericFormat } from 'react-number-format';


export default function AccountReceivableByMonth() {
    const [isLoading, setLoading] = React.useState(false);

    const [people, setPeople] = useState([]);
    const [openSnackbar] = useSnackbar();

    const [data, setData] = React.useState([]);
    const [grouping, setGrouping] = React.useState<GroupingState>([]);
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = React.useState("");
    const [isConsultatnVisible, setisConsultatnVisible] = React.useState(false);
    const [columnVisibilityState, setColumnVisibilityState] = React.useState({});

    const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
        // Rank the item
        const itemRank = rankItem(row.getValue(columnId), value);

        // Store the itemRank info
        addMeta({
            itemRank,
        });

        // Return if the item should be filtered in/out
        return itemRank.passed;
    };

    const columns = React.useMemo<ColumnDef<FinanceRecord, any>[]>(
        () => [
            {
                accessorKey: "month",
                header: "Month",
                cell: (info) => info.getValue(),
                // enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                // columnVisibility: false,
                meta: {
                    cellClassName: "text-left",
                },
                footer: ({ table }) => (
                    <>
                        <span className="font-semibold text-md">Total</span>
                    </>
                ),
            },
            {
                accessorKey: "hrs",
                header: "Efforts (Hrs)",
                cell: (info) => Number(info.getValue()) | 0,
                enableGrouping: false,
                enableColumnFilter: false,
                meta: {
                    cellClassName: "text-right",
                },
                footer: ({ table }) => (
                    <>
                        {/* <span className="text-xs font-normal">Total Efforts</span>
                        <br /> */}
                        {/* <span className="text-md">{table.getFilteredRowModel().rows.reduce((total, row) => total + row.original.hrs, 0)}</span> */}
                        <NumericFormat value={Number(table.getFilteredRowModel().rows.reduce((total, row) => total + row.original.hrs, 0)) || 0} allowLeadingZeros displayType="text" thousandSeparator="," />

                    </>
                ),
            },
            {
                accessorKey: "bill",
                header: "Bill Amount (USD)",
                cell: (info) => <Currency quantity={Number(info.getValue()) || 0} currency="USD" />,
                // enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                // columnVisibility: false,
                meta: {
                    cellClassName: "text-right",
                },
                footer: ({ table }) => (
                    <>
                        {/* <span className="text-xs font-normal">Total Bill</span>
                        <br /> */}
                        <Currency quantity={Number(table.getFilteredRowModel().rows.reduce((total, row) => total + row.original.bill, 0)) || 0} currency="USD" />
                    </>
                ),
            },
            {
                accessorKey: "payment",
                header: "Amount Recieved (USD)",
                cell: (info) => <Currency quantity={Number(info.getValue()) || 0} currency="USD" />,
                // enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                // columnVisibility: false,
                meta: {
                    cellClassName: "text-right",
                },
                footer: ({ table }) => (
                    <>
                        {/* <span className="text-xs font-normal">Total Payment Recieved</span>
                        <br /> */}
                        <Currency quantity={Number(table.getFilteredRowModel().rows.reduce((total, row) => total + row.original.payment, 0)) || 0} currency="USD" />
                    </>
                ),
            },
            {
                accessorKey: "diff",
                header: "Balance",
                cell: (info) => <Currency quantity={Number(info.getValue()) || 0} currency="USD" />,
                // enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                // columnVisibility: false,
                meta: {
                    cellClassName: classNames("text-right"),
                },
                footer: ({ table }) => (
                    <>
                        {/* <span className="text-xs font-normal">Total Dues</span>
                        <br /> */}
                        <Currency quantity={Number(table.getFilteredRowModel().rows.reduce((total, row) => total + row.original.diff, 0)) || 0} currency="USD" />
                    </>
                ),
            },
        ],
        []
    );

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        initialState: {
            // columnVisibility: {
            //     consultant: false,
            // },
            // hiddenColumns: columns.filter((col) => col.show === false).map((col) => col.accessor),
            // hiddenColumns: ["accesorName"],
            // hiddenColumns: columns.filter((column) => !column?.isVisible).map((column) => column.accessor),
        },
        state: {
            columnVisibility: columnVisibilityState,
            grouping,
            columnFilters,
            globalFilter,
        },
        onGroupingChange: setGrouping,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        // getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        // debugTable: true,
        // debugHeaders: true,
        // debugColumns: true,
    });

    const data1 = (groupCol: string[], visibility: any) => {
        console.log(visibility);
        console.log(groupCol);

        table.setColumnVisibility(visibility);
        setColumnVisibilityState(visibility);
        setGrouping(groupCol);
    };

    useEffect(() => {
        setLoading(true);

        setPeople([]);
        setData([]);

        getFinanceReportDataByMonth({
            checkDate: new Date(),
            employee: "",
        }).then((data) => {
            setData(data);
        });

        listOfEmployees()
            .then((data) => setPeople(data))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    function applyFilters(filters): any {
        console.log(filters);

        setLoading(true);
        setData([]);

        getFinanceReportDataByMonth(filters)
            .then((data) => {
                setData(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <main>
            <Breadcrums breadcrumLinks={monthlyAccountRecievableBreadcrums} commands={paymentsCommands} groups={["Statement"]} module={"Payment"} />
            <PageTitle title="Monthly Account Recievable Statement" preSummary="Comprehensive Report" summary="Analyzing Account Receivables, Payments, Settlements, and Dues for Strategic Insights."></PageTitle>

            <div className={classNames(sectionClass)}>
                <YearFilter people={people} applyFilters={applyFilters} />
                {/* <PortalTable table={table} handler={data1} /> */}
                {data && data.length > 0 && <TanstackTable columns={columns} data={data} downloadBtn={true}></TanstackTable>}
            </div>
        </main>
    );
}
