export async function timesheetDetails(filters: any) {
    // console.log(filters);

    console.log(process.env.REACT_APP_API_URL);

    // IF checkDate

    // const _q = "select finance.uuid, consultant.name,  month(_d), sum(hrs) as totalHrs from finance, consultant where finance.uuid = consultant.uuid and  year(_d) = " + filters.checkDate.getFullYear() + " and month(_d) = " + (filters.checkDate.getMonth() + 1) + " group by uuid, name, month(_d) order by name";

    let _q = "select finance.uuid, consultant.name, finance.pid,  month(_d) as _m, MONTHNAME(_d)  as month1, YEAR(_d) AS year, DATE_FORMAT(_d, '%M-%Y') AS month, sum(hrs) as totalHrs from finance, consultant where finance.uuid = consultant.uuid ";

    if (filters && filters.checkDate) {
        _q += "  and month(_d) = " + (filters.checkDate.getMonth() + 1) + "";
        _q += "  and year(_d) = " + filters.checkDate.getFullYear() + "";
    }

    if (filters && filters.employee) {
        _q += "  and finance.uuid = '" + filters.employee + "' ";
    }

    _q += " group by uuid, name, month1, year order by name, _d";

    console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function timesheetStatementByMonth(filters: any) {
    console.log(filters);

    let _q = "select finance.uuid, consultant.name,  month(_d) as _m, MONTHNAME(_d)  as month1, YEAR(_d) AS year, DATE_FORMAT(_d, '%M-%Y') AS month, sum(hrs) as totalHrs from finance, consultant where finance.uuid = consultant.uuid ";

    if (filters && filters.checkDate) {
        _q += "  and year(_d) = " + filters.checkDate.getFullYear() + "";
    }

    if (filters && filters.employee) {
        _q += "  and finance.uuid = '" + filters.employee + "' ";
    }

    _q += " group by _m order by _m";

    console.log(_q);

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/dynamic", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: _q,
                params: ["finance"],
            }),
        });
        return await response.json();
    } catch (error) {
        return [];
    }
}
