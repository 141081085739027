import React, { useContext } from "react";
import Stats from "../blocks/stats";
import UserContext from "../components/TheUser";
import { Popover } from "@headlessui/react";
import Breadcrums from "../components/breadcrums";
import PageTitle from "../components/pageTitle";
import classNames from "classnames";
import { sectionClass } from "../constants/common";

const breadcrumLinks = [{ name: "Dashboard", href: "/dashboard", current: true }];

export default function Dashboard() {
    const userData = useContext(UserContext);

    return (
        <main>
            <Breadcrums breadcrumLinks={breadcrumLinks} module={"Dashboard"} />
            <PageTitle title="Dashboard" preSummary="Operational Excellence Dashboard" summary="Snapshot Report">
                {/* , July 2023 */}
            </PageTitle>

            <section className={classNames(sectionClass)}>
                <Stats />
            </section>

            {/* <section>
                <h1>{JSON.stringify(userData)}</h1>
            </section> */}
        </main>
    );
}
