import React, { useEffect, useState } from "react";
import Breadcrums from "../components/breadcrums";
import { Link } from "react-router-dom";
import { getFilteredPayroll } from "../api/payroll-api";
import Moment from "react-moment";
import PayrollDrawer from "../components/payoutDrawer";
import { getEmployeeRecord, listOfEmployees } from "../api/consultants-api";
import CommonFilters from "../blocks/commonFilters";
import Currency from "react-currency-formatter";
import classNames from "classnames";
import { nanoid } from "nanoid";
import { useSnackbar } from "react-simple-snackbar";
import Skeleton from "react-loading-skeleton";
import PageTitle from "../components/pageTitle";
import { payrollBreadcrums } from "../constants/breadcrums";
import { alternativeBgClr, blueBgClr, button, buttonBase, payrollCommandActions } from "../constants/common";
import { ColumnDef } from "@tanstack/react-table";
import { PayrollType, consultant } from "../model";
import TanstackTable from "../components/TanstackTable";
import moment from "moment";
// import PdfViewer from "../components/pdfViewer";

export default function Payroll() {
    const [payrollList, setPayrollList] = useState([]);

    const [isLoading, setLoading] = React.useState(false);

    const [people, setPeople] = useState([]);
    const [openSnackbar] = useSnackbar();

    const defaultStartDate = moment().subtract(0, "d").toDate(); // moment().subtract(15, "d").toDate();

    const toUTCFormat = (d: any) => {
        // console.log(d);
        return moment.utc(moment(d)).format("MM/DD/YYYY");
        // return moment(d).add(1, "day").format();
    };

    useEffect(() => {
        setLoading(true);
        // listOfPayroll().then((data) => setPayrollList(data));
        setPayrollList([]);
        getFilteredPayroll({
            checkDate: defaultStartDate,
            // startDate: defaultStartDate,
            employee: "",
        })
            .then((data) => {
                console.log(data);
                if (data.error) {
                    openSnackbar(data.error ? data.error.sqlMessage : "");
                }

                setPayrollList(data || []);
            })
            .finally(() => {
                setLoading(false);
            });

        // Reset Data
        setPeople([]);
        listOfEmployees()
            .then((data) => setPeople(data))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    function applyFilters(filters): any {
        console.log(filters);
        getFilteredPayroll(filters).then((data) => {
            console.log(data);
            setPayrollList(data);
        });
    }

    const columns = React.useMemo<ColumnDef<PayrollType, any>[]>(
        () => [
            {
                accessorKey: "uuid",
                header: "Name",
                cell: ({ row }) => {
                    <b>{row.original.uuid}</b>;
                },
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
            },
            {
                accessorKey: "_d_start",
                header: "Pay-Check Period",
                cell: ({ row }) => (
                    <>
                        <Moment filter={toUTCFormat} local>
                            {row.original._d_start}
                        </Moment>{" "}
                        -{" "}
                        <Moment filter={toUTCFormat} local>
                            {row.original._d_end}
                        </Moment>
                    </>
                ),
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
            },
            {
                accessorKey: "net_pay",
                header: "Total Net Pay ",
                cell: ({ row }) => (
                    <>
                        <Currency quantity={row.original.net_pay || 0} currency="USD" />
                        <small className="text-xs font-medium text-gray-500">(USD)</small>
                    </>
                ),

                enableColumnFilter: false,
                enableGrouping: false,
                // Footer: <span>{_.sum(_.map(data, d => d.total))}</span>
                // Footer: (info) => _.sum(info.filteredRows.map((row) => row.values.costs)),
                Footer: 0,
                // enableSorting: true,
            },
            {
                accessorKey: "uuid",
                header: "",
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
            },
        ],
        []
    );

    return (
        <main>
            <Breadcrums breadcrumLinks={payrollBreadcrums} commands={payrollCommandActions} module={"Payroll"} groups={["Actions", "Statement"]} />
            <PageTitle title={"Payroll"} preSummary={"Organizational Overview"} summary={"Snapshot of Current Employee Roster and Vital Information."}>
                <Link to="/payroll/import">
                    {/* className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-900 border border-transparent rounded-md shadow-sm hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto" */}
                    <button type="button" className={classNames(button, buttonBase, alternativeBgClr, "px-2 mx-2")}>
                        Import Payroll
                    </button>
                </Link>

                <Link to="/payroll/new">
                    <button type="button" className={classNames(button, buttonBase, blueBgClr)}>
                        New Payroll
                    </button>
                </Link>
            </PageTitle>

            <section className="px-4 py-0 sm:px-6 lg:px-8">
                <CommonFilters people={people} applyFilters={applyFilters}></CommonFilters>
                {/* {payrollList.length > 0 && <TanstackTable columns={columns} data={payrollList}></TanstackTable>} */}
            </section>

            {/* <PdfViewer></PdfViewer> */}

            <section className="px-4 py-0 sm:px-6 lg:px-8">
                <div className="flex flex-col mt-8">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                Name
                                            </th>

                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Pay-Check Period
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Total Net Pay <small className="text-xs font-medium text-gray-500">(USD)</small>{" "}
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Hours
                                            </th>
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {!isLoading && payrollList.length == 0 && (
                                            <tr>
                                                <td className="p-2 pl-4 text-sm font-normal text-gray-700 whitespace-nowrap sm:pl-6" colSpan={4}>
                                                    No records found
                                                </td>
                                            </tr>
                                        )}

                                        {isLoading && (
                                            <>
                                                <tr>
                                                    <td className="pl-4 pr-3 text-sm font-normal text-gray-900 whitespace-nowrap sm:pl-6">
                                                        <Skeleton />
                                                    </td>
                                                    <td className="px-3 text-sm text-gray-500 whitespace-nowrap">
                                                        <Skeleton />
                                                    </td>
                                                    <td className="px-3 text-sm text-gray-500 whitespace-nowrap">
                                                        <Skeleton />
                                                    </td>
                                                    <td className="px-3 text-sm text-gray-500 whitespace-nowrap">
                                                        <Skeleton />
                                                    </td>
                                                    <td className="relative py-2 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                                                        <Skeleton />
                                                    </td>
                                                </tr>
                                            </>
                                        )}

                                        {payrollList.length > 0 &&
                                            payrollList.map((payroll: any, payrollIdx) => (
                                                <tr key={nanoid()} className={classNames(payrollIdx % 2 === 0 ? undefined : "bg-gray-50-x", " pl-4 pr-3 px-3 py-1.5 text-sm sm:pl-6 text-gray-900 hover:bg-gray-100")}>
                                                    <td className="pl-4 font-normal whitespace-nowrap sm:pl-6"> {getEmployeeRecord(people, payroll.uuid)?.name || payroll.uuid}</td>
                                                    <td className="pl-4 whitespace-nowrap">
                                                        <Moment filter={toUTCFormat} local>
                                                            {payroll._d_start}
                                                        </Moment>{" "}
                                                        -{" "}
                                                        <Moment filter={toUTCFormat} local>
                                                            {payroll._d_end}
                                                        </Moment>
                                                    </td>

                                                    {/* py-4 pl-4 pr-3  sm:pl-6 */}
                                                    <td className="pl-4 text-sm font-normal text-left text-gray-900 whitespace-nowrap">
                                                        {" "}
                                                        <Currency quantity={payroll.net_pay || 0} currency="USD" />
                                                        {/* <small className="text-xs font-medium text-gray-500">(USD)</small> */}
                                                    </td>

                                                    <td className="pl-4 text-sm font-normal text-left text-gray-900 whitespace-nowrap">{payroll.hrs}</td>

                                                    <td className="relative py-2 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                                                        {/* <a href={`/payroll/${payroll.uuid}`} className="text-indigo-600 hover:text-blue-900">
                                                            View Paystub<span className="sr-only">, {payroll.uuid}</span>
                                                        </a> */}

                                                        {/* {payroll.uuid} */}

                                                        <Link
                                                            to={`/payroll/${payroll.uuid}`}
                                                            preventScrollReset={true}
                                                            state={{
                                                                checkDate: payroll.check_date,
                                                                uuid: payroll.uuid,
                                                                payroll: payroll,
                                                            }}
                                                            // target="_blank"
                                                            className="text-blue-600 no-underline hover:text-blue-900 no-line-through"
                                                        >
                                                            View<span className="sr-only">, {payroll.uuid}</span>
                                                        </Link>

                                                        {/* <PayrollDrawer payroll={payroll} employee={getEmployeeRecord(people, payroll.uuid)}></PayrollDrawer> */}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
