import React, { useEffect, useState } from "react";
import Breadcrums from "../../../components/breadcrums";
import { timesheetBreadcrums } from "../../../constants/breadcrums";
import { sectionClass, timesheetCommands } from "../../../constants/common";
import PageTitle from "../../../components/pageTitle";
import { TimesheetType } from "../../../model";
import { ColumnDef } from "@tanstack/react-table";
import { listOfEmployees } from "../../../api/consultants-api";
import { timesheetDetails, timesheetStatementByMonth } from "../../../api/timesheet-api";
import classNames from "classnames";
import CommonFilters from "../../../blocks/commonFilters";
import TanstackTable from "../../../components/TanstackTable";
import YearFilter from "../../../blocks/yearFilters";

export default function TimesheetMonthwise() {
    const [isLoading, setLoading] = React.useState(false);
    const [people, setPeople] = useState([]);

    const [data, setData] = React.useState([]);

    const columns = React.useMemo<ColumnDef<TimesheetType, any>[]>(
        () => [
            {
                accessorKey: "month",
                header: "Month",
                cell: (info) => info.getValue(),
                // enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                // columnVisibility: false,
                meta: {
                    cellClassName: "text-left",
                },
                footer: ({ table }) => (
                    <>
                        <span className="font-semibold text-md">Total</span>
                    </>
                ),
            },

            {
                accessorKey: "totalHrs",
                header: "Efforts (Hrs)",
                cell: (info) => Number(info.getValue()) | 0,
                enableGrouping: false,
                enableColumnFilter: false,
                meta: {
                    cellClassName: "text-right",
                },
                footer: ({ table }) => (
                    <>
                        {/* <span className="text-xs font-normal">Total Efforts</span>
                        <br /> */}
                        <span className="text-md">{table.getFilteredRowModel().rows.reduce((total, row) => total + row.original.totalHrs, 0)}</span>
                    </>
                ),
            },
        ],
        []
    );

    useEffect(() => {
        console.log("Loading Statement ...");
    }, []);

    function applyFilters(filters): any {
        console.log(filters);
        setLoading(true);
        setData([]);

        timesheetStatementByMonth(filters)
            .then((data) => {
                setData(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            <main>
                <Breadcrums breadcrumLinks={timesheetBreadcrums} commands={timesheetCommands} module={"Timesheet"} groups={["Statement"]} />
                <PageTitle title={"Monthly Timesheet Statement"} preSummary={"Organizational Overview"} summary={"Overview of monthly total of individual employee."}></PageTitle>

                <div className={classNames(sectionClass)}>
                    {/* <CommonFilters people={people} applyFilters={applyFilters} /> */}
                    <YearFilter people={people} applyFilters={applyFilters} />

                    {data && data.length > 0 && <TanstackTable columns={columns} data={data} downloadBtn={true}></TanstackTable>}
                </div>
            </main>
        </>
    );
}
