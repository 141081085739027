import React from "react";
import Breadcrums from "../../../components/breadcrums";
import { payrollBreadcrums, payrollMonthlyReportBreadcrums } from "../../../constants/breadcrums";
import { payrollCommandActions } from "../../../constants/common";
import PageTitle from "../../../components/pageTitle";
import TanstackTable from "../../../components/TanstackTable";

export default function MonthlySnapshotReport() {
    return (
        <>
            <main>
                <Breadcrums breadcrumLinks={payrollMonthlyReportBreadcrums} commands={payrollCommandActions} module={"Payroll"} />
                <PageTitle title="Payroll Monthly Snapshot Report" preSummary="Payroll Comprehensive Operational Time-sensitive facts" summary="provides a snapshot of a business's payroll obligations during a specific time frame"></PageTitle>
            </main>
        </>
    );
}
