import React, { useEffect, useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import Skeleton from "react-loading-skeleton";

import moment from "moment";
import Moment from "react-moment";

import { getEmployeeRecord, listOfEmployees } from "../../../api/consultants-api";

import Currency from "react-currency-formatter";
import { ColumnDef } from "@tanstack/react-table";

import { PayrollType } from "../../../model";
import Breadcrums from "../../../components/breadcrums";
import { payrollBreadcrums } from "../../../constants/breadcrums";
import { payrollCommandActions } from "../../../constants/common";
import { Link } from "react-router-dom";
import PageTitle from "../../../components/pageTitle";
import CommonFilters from "../../../blocks/commonFilters";
import classNames from "classnames";
import { nanoid } from "nanoid";
import PayrollDrawer from "../../../components/payoutDrawer";
import { getFilteredPayrollSummary } from "../../../api/payroll-api";
import TanstackTable from "../../../components/TanstackTable";
import PayrollStats from "../../../blocks/payrollStats";

export default function PayrollDetails() {
    const [payrollList, setPayrollList] = useState([]);

    const [isLoading, setLoading] = React.useState(false);

    const [people, setPeople] = useState([]);
    const [openSnackbar] = useSnackbar();

    const defaultStartDate = moment().subtract(0, "d").toDate();

    useEffect(() => {
        setLoading(true);
        // listOfPayroll().then((data) => setPayrollList(data));
        setPayrollList([]);
        getFilteredPayrollSummary({
            checkDate: defaultStartDate,
            employee: "",
            groupedUUID: true,
        })
            .then((data) => {
                console.log(data);
                if (data.error) {
                    openSnackbar(data.error ? data.error.sqlMessage : "");
                }

                setPayrollList(data || []);
            })
            .finally(() => {
                setLoading(false);
            });

        // Reset Data
        setPeople([]);
        listOfEmployees()
            .then((data) => setPeople(data))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const columns = React.useMemo<ColumnDef<PayrollType, any>[]>(
        () => [
            {
                accessorKey: "uuid",
                header: "Name",
                cell: ({ row }) => {
                    // getEmployeeRecord(people, row.original.uuid)?.name || row.original.uuid;
                    <b>{row.original.uuid}</b>;
                },
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
            },
            {
                accessorKey: "check_date",
                header: "Check Date",
                cell: ({ row }) => (
                    <>
                        <Moment format="YYYY/MM/DD">{row.original.check_date}</Moment>
                    </>
                ),
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
            },
            {
                accessorKey: "_s_np",
                header: "Total Net Pay ",
                cell: ({ row }) => (
                    <>
                        <Currency quantity={row.original?._s_np || 0} currency="USD" />
                        <small className="text-xs font-medium text-gray-500">(USD)</small>
                    </>
                ),

                enableColumnFilter: false,
                enableGrouping: false,
                // Footer: <span>{_.sum(_.map(data, d => d.total))}</span>
                // Footer: (info) => _.sum(info.filteredRows.map((row) => row.values.costs)),
                Footer: 0,
                // enableSorting: true,
            },
            {
                accessorKey: "uuid",
                header: "",
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
            },
        ],
        []
    );

    function applyFilters(filters): any {
        console.log(filters);
        filters.groupedUUID = true;

        getFilteredPayrollSummary(filters).then((data) => setPayrollList(data));
    }

    return (
        <main>
            <Breadcrums breadcrumLinks={payrollBreadcrums} commands={payrollCommandActions} module={"Payroll"} groups={["Actions", "Statement"]} />
            <PageTitle title={"Payroll Details"} preSummary={"Organizational Overview"} summary={"Snapshot of Current Employee Roster and Vital Information."}></PageTitle>

            <section className="px-4 py-0 sm:px-6 lg:px-8">
                <CommonFilters people={people} applyFilters={applyFilters}></CommonFilters>
                {/* {payrollList.length > 0 && <TanstackTable columns={columns} data={payrollList}></TanstackTable>} */}
            </section>

            {payrollList.length > 0 && (
                <section className="px-4 py-0 mt-8 sm:px-6 lg:px-8">
                    <PayrollStats data={payrollList} />
                </section>
            )}

            <section className="px-4 py-0 sm:px-6 lg:px-8">
                <div className="flex flex-col mt-8">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                {/* {payrollList.length > 0 && <TanstackTable columns={columns} data={payrollList}></TanstackTable>} */}

                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                Name
                                            </th>

                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                Total Efforts <small className="text-xs font-medium text-gray-500">(Hrs)</small>{" "}
                                            </th>

                                            {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Check Month
                                            </th> */}
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Total Earnings <small className="text-xs font-medium text-gray-500">(USD)</small>{" "}
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Total Deductions <small className="text-xs font-medium text-gray-500">(USD)</small>{" "}
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Total Taxes <small className="text-xs font-medium text-gray-500">(USD)</small>{" "}
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Total Net Pay <small className="text-xs font-medium text-gray-500">(USD)</small>{" "}
                                            </th>
                                            {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">Edit</span>
                                            </th> */}
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {!isLoading && payrollList.length == 0 && (
                                            <tr>
                                                <td className="p-2 pl-4 text-sm font-normal text-gray-700 whitespace-nowrap sm:pl-6" colSpan={4}>
                                                    No records found
                                                </td>
                                            </tr>
                                        )}

                                        {isLoading && (
                                            <>
                                                <tr>
                                                    <td className="pl-4 pr-3 text-sm font-normal text-gray-900 whitespace-nowrap sm:pl-6">
                                                        <Skeleton />
                                                    </td>
                                                    <td className="px-3 text-sm text-gray-500 whitespace-nowrap">
                                                        <Skeleton />
                                                    </td>
                                                    <td className="px-3 text-sm text-gray-500 whitespace-nowrap">
                                                        <Skeleton />
                                                    </td>

                                                    <td className="px-3 text-sm text-gray-500 whitespace-nowrap">
                                                        <Skeleton />
                                                    </td>
                                                    {/* <td className="relative py-2 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                                                        <Skeleton />
                                                    </td> */}
                                                </tr>
                                            </>
                                        )}

                                        {payrollList.length > 0 &&
                                            payrollList.map((payroll: any, payrollIdx) => (
                                                <tr key={nanoid()} className={classNames(payrollIdx % 2 === 0 ? undefined : "bg-gray-50-x", " pl-4 pr-3 px-3 py-1.5 text-sm sm:pl-6 text-gray-900 hover:bg-gray-100")}>
                                                    <td className="py-2 pl-4 font-normal whitespace-nowrap sm:pl-6"> {getEmployeeRecord(people, payroll.uuid)?.name || payroll.uuid}</td>
                                                    <td className="py-2 pl-4 font-normal whitespace-nowrap sm:pl-6"> {payroll._s_hrs} </td>
                                                    {/* <td className="pl-4 whitespace-nowrap">
                                                        <Moment format="MMMM-YYYY">{payroll.check_date}</Moment>
                                                    </td> */}

                                                    {/* py-4 pl-4 pr-3  sm:pl-6 */}
                                                    <td className="pl-4 text-sm font-normal text-left text-gray-900 whitespace-nowrap">
                                                        {" "}
                                                        <Currency quantity={payroll._s_e_total || 0} currency="USD" />
                                                        {/* <small className="text-xs font-medium text-gray-500">(USD)</small> */}
                                                    </td>

                                                    <td className="pl-4 text-sm font-normal text-left text-gray-900 whitespace-nowrap">
                                                        {" "}
                                                        <Currency quantity={payroll._s_d_total || 0} currency="USD" />
                                                        {/* <small className="text-xs font-medium text-gray-500">(USD)</small> */}
                                                    </td>

                                                    <td className="pl-4 text-sm font-normal text-left text-gray-900 whitespace-nowrap">
                                                        {" "}
                                                        <Currency quantity={payroll._s_t_total || 0} currency="USD" />
                                                        {/* <small className="text-xs font-medium text-gray-500">(USD)</small> */}
                                                    </td>

                                                    <td className="pl-4 text-sm font-normal text-left text-gray-900 whitespace-nowrap">
                                                        {" "}
                                                        <Currency quantity={payroll._s_np || 0} currency="USD" />
                                                        {/* <small className="text-xs font-medium text-gray-500">(USD)</small> */}
                                                    </td>

                                                    {/* <td className="relative py-2 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6"> */}
                                                    {/* <a href={`/employees/${payroll.uuid}`} className="text-indigo-600 hover:text-blue-900">
                                                            Edit<span className="sr-only">, {payroll.uuid}</span>
                                                        </a> */}

                                                    {/* <PayrollDrawer payroll={payroll} employee={getEmployeeRecord(people, payroll.uuid)}></PayrollDrawer> */}
                                                    {/* </td> */}
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
