import { Tab } from "@headlessui/react";
import { useState } from "react";

import classNames from "classnames";
import EmployeeDetailsForm from "./employeeDetailsForm";
import QualificationDetails from "./qualificationDetails";
import ExperienceDetails from "./experienceDetails";
import ProjectDetails from "./projectDetails";
import EmployeeDocuments from "./employeeDocuments";
import PaystubDetails from "./paystubDetails";

// function classNames(...classes: string[]) {
//     return classes.filter(Boolean).join(" ");
// }

export default function EmployeeDetailsTabs(props: { profile: any; consultantDetails: any }) {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const tabCss = "group relative focus:ring-0 focus:border-transparent min-w-0 flex-1 overflow-hidden py-2 px-4 text-sm font-medium text-center focus:z-0 absolute inset-x-0 bottom-0 ring-0 focus:ring-0";
    const activeTabCss = "bg-blue-900 text-white font-bold";
    const passiveTabCss = "bg-white text-black";

    return (
        <Tab.Group defaultIndex={1} selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <>
                <Tab.List className="relative z-0 flex divide-x divide-gray-200 rounded-lg shadow">
                    {/* <Tab className={classNames(tabCss, activeTabCss, passiveTabCss)}>Tab 1</Tab> */}
                    <Tab className={({ selected }) => classNames(tabCss, selected ? activeTabCss : passiveTabCss, "rounded-l-lg")}>Personnel Details</Tab>
                    <Tab className={({ selected }) => classNames(tabCss, selected ? activeTabCss : passiveTabCss)}>Qualifications</Tab>
                    <Tab className={({ selected }) => classNames(tabCss, selected ? activeTabCss : passiveTabCss)}>Experience</Tab>
                    <Tab className={({ selected }) => classNames(tabCss, selected ? activeTabCss : passiveTabCss)}>Project Details</Tab>
                    {/* <Tab className={({ selected }) => classNames(tabCss, selected ? activeTabCss : passiveTabCss)}>Travel Details</Tab> */}
                    <Tab className={({ selected }) => classNames(tabCss, selected ? activeTabCss : passiveTabCss, "")}>Paystub</Tab>
                    <Tab className={({ selected }) => classNames(tabCss, selected ? activeTabCss : passiveTabCss, "rounded-r-lg")}>Documents</Tab>
                </Tab.List>

                <Tab.Panels className={"pt-5"}>
                    <Tab.Panel>
                        <EmployeeDetailsForm profile={props.profile} />
                    </Tab.Panel>
                    <Tab.Panel>
                        <QualificationDetails consultantDetails={props.consultantDetails} />
                    </Tab.Panel>
                    <Tab.Panel>
                        <ExperienceDetails consultantDetails={props.consultantDetails} />
                    </Tab.Panel>
                    <Tab.Panel>
                        <ProjectDetails profile={props.profile} />
                    </Tab.Panel>
                    <Tab.Panel>
                        <PaystubDetails profile={props.profile} />
                    </Tab.Panel>
                    {/* <Tab.Panel>
                        <EmployeeTravelDetails />
                    </Tab.Panel> */}

                    <Tab.Panel>
                        <EmployeeDocuments consultantDetails={props.consultantDetails} />
                    </Tab.Panel>
                </Tab.Panels>
            </>
        </Tab.Group>
    );
}
