/* This example requires Tailwind CSS v2.0+ */
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";

import EmployeeDetailsTabs from "../blocks/employeeDetailsTabs";
import Breadcrums from "../components/breadcrums";
import { addUpdateConsultant, getConsultantDetails, getProfile } from "../api/consultants-api";
import ExperienceDetails from "../blocks/experienceDetails";
import PageTitle from "../components/pageTitle";
import { MailIcon, PhoneIcon } from "@heroicons/react/solid";

// import { api } from "../convex/_generated/api";
// import { useQuery } from "convex/react";
// import { Id } from "../convex/_generated/dataModel";
// import { v } from "convex/values";

const profile = {
    name: "Ricardo Cooper",
    email: "ricardo.cooper@example.com",
    avatar: "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    backgroundImage: "https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
    fields: [
        ["Phone", "(555) 123-4567"],
        ["Email", "ricardocooper@example.com"],
        ["Title", "Senior Front-End Developer"],
        ["Team", "Product Development"],
        ["Location", "San Francisco"],
        ["Sits", "Oasis, 4th floor"],
        ["Salary", "$145,000"],
        ["Birthday", "June 8, 1990"],
    ],
};

const breadcrumLinks = [
    { name: "Employees", href: "/employees", current: false },
    { name: "Employee Profile", href: "#", current: true },
];

export default function Employee() {
    const { handle } = useParams();

    const [emp, setEmp] = useState({ name: "", avatar: "", email: "", active: 0, uuid: "" });
    const [consultantDetails, setConsultantDetails] = useState({});

    const isNew = handle === "0" ? true : false;

    const navigate = useNavigate();

    // const cId: v.id("consultant") = "340bzjb0rdyscwv02c90106k9hqds4g";
    // const x = useQuery(api.consultant.getConsultant, { consultantId: "340bzjb0rdyscwv02c90106k9hqds4g" });

    React.useEffect(() => {
        console.log(handle);

        getProfile(handle).then((data) => {
            setEmp(data[0]);

            getConsultantDetails(handle).then((r) => {
                for (let i of r) {
                    // i.details = JSON.parse(i.data);
                    Object.assign(i, JSON.parse(i.data));
                }
                setConsultantDetails(r);
            });
        });
    }, [handle]);

    // addDefaultSrc(ev) {
    //     ev.target.src = 'some default image url'
    // }

    return (
        <div>
            {/* <div>
                <img className="object-cover w-full h-32 lg:h-48" src={profile.backgroundImage} alt="" />
            </div> */}

            <Breadcrums breadcrumLinks={breadcrumLinks} module={"Employee"} />
            <PageTitle title={emp?.name} summary={emp?.email} preSummary="">
                {emp?.uuid && emp?.active == 1 && (
                    <button
                        type="button"
                        onClick={() => {
                            emp.active = 0;
                            addUpdateConsultant(emp).then((result: any) => {
                                console.log(result);
                                console.log(emp);
                                navigate("/employees");
                                // if (result) {
                                //     openSnackbar(result.error ? result.error.sqlMessage : "Consultant Saved.");
                                // } else openSnackbar("Some Error!");
                            });
                        }}
                        className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-red-900 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                        De-activate User
                    </button>
                )}

                {emp?.uuid && emp?.active == 0 && (
                    <button
                        type="button"
                        onClick={() => {
                            emp.active = 1;
                            addUpdateConsultant(emp).then((result: any) => {
                                console.log(result);
                                console.log(emp);
                                navigate("/employees");
                                // if (result) {
                                //     openSnackbar(result.error ? result.error.sqlMessage : "Consultant Saved.");
                                // } else openSnackbar("Some Error!");
                            });
                        }}
                        className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-green-900 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                        Activate User
                    </button>
                )}

                {/* {!isNew && (
                    <div className="flex flex-col mt-6 space-y-3 justify-stretch sm:flex-row sm:space-y-0 sm:space-x-4">
                        <button type="button" className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                            <MailIcon className="w-5 h-5 mr-2 -ml-1 text-gray-400" aria-hidden="true" />
                            <span>Message</span>
                        </button>
                        <button type="button" className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                            <PhoneIcon className="w-5 h-5 mr-2 -ml-1 text-gray-400" aria-hidden="true" />
                            <span>Call</span>
                        </button>
                    </div>
                )} */}
            </PageTitle>

            <div className="max-w-full px-4 mx-auto sm:px-6 lg:px-8">
                {/* <div className="flex-1 hidden min-w-0 mt-2 sm:block md:hidden">
                    <h1 className="text-2xl font-bold text-gray-900 truncate">{emp?.name}</h1>
                </div> */}

                <section className="pt-2">
                    {/* <ProfileTabs /> */}
                    {!isNew && emp?.name !== "" && <EmployeeDetailsTabs profile={emp} consultantDetails={consultantDetails} />}
                </section>
            </div>
        </div>
    );
}
