/* This example requires Tailwind CSS v2.0+ */
import { PaperClipIcon } from "@heroicons/react/solid";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { Documents } from "../model";


    export default function EmployeeDocuments(props: { consultantDetails: any }) {
        const [items, setItems] = useState([]);
    
        useEffect(() => {
            if (props.consultantDetails) {
                setItems(props.consultantDetails.filter((q) => q.type == "Documents"));
            }
        }, []);

    return (
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Applicant Documents</h3>
                <p className="max-w-2xl mt-1 text-sm text-gray-500">Documents List</p>
            </div>
            <div className="px-4 py-5 border-gray-200 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    {/* <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Full name</dt>
                        <dd className="mt-1 text-sm text-gray-900">Margot Foster</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Application for</dt>
                        <dd className="mt-1 text-sm text-gray-900">Backend Developer</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Email address</dt>
                        <dd className="mt-1 text-sm text-gray-900">margotfoster@example.com</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Salary expectation</dt>
                        <dd className="mt-1 text-sm text-gray-900">$120,000</dd>
                    </div> */}

                    <div className="sm:col-span-2">
                        {/* <dt className="text-sm font-medium text-gray-500">Documents</dt> */}
                        <dd className="mt-0 text-sm text-gray-900">

                            <ul key={nanoid()} role="list" className="border border-0 divide-y divide-gray-200 rounded-md border-gray-0">
                            {items.map((item: Documents) => (

                                
                                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                    <div className="flex items-center flex-1 w-0">
                                        <PaperClipIcon className="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
                                        <span className="flex-1 w-0 ml-2 truncate">{item.name}</span>
                                    </div>
                                    {/* <div className="flex-shrink-0 ml-4">
                                        <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                                            Download
                                        </a>
                                    </div> */}
                                </li>))}
                               
                            </ul>
                            {items.length == 0 && <>No Documents found.</>}

                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    );
}
