import { Fragment, JSXElementConstructor, ReactElement, ReactNode, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { ClockIcon, HomeIcon, MenuAlt1Icon, ViewListIcon, XIcon } from "@heroicons/react/outline";
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon } from "@heroicons/react/solid";
import ProjectDrawer from "./projectDrawer";
import { nanoid } from "nanoid";
import { Id } from "../convex/_generated/dataModel";
import Moment from "react-moment";
import Skeleton from "react-loading-skeleton";

const navigation = [
    { name: "Home", href: "#", icon: HomeIcon, current: true },
    { name: "My tasks", href: "#", icon: ViewListIcon, current: false },
    { name: "Recent", href: "#", icon: ClockIcon, current: false },
];
const teams = [
    { name: "Engineering", href: "#", bgColorClass: "bg-indigo-500" },
    { name: "Human Resources", href: "#", bgColorClass: "bg-green-500" },
    { name: "Customer Success", href: "#", bgColorClass: "bg-yellow-500" },
];
const projects = [
    {
        id: 1,
        title: "ERP 360",
        initials: "GA",
        team: "Engineering",
        members: [
            {
                name: "Dries Vincent",
                handle: "driesvincent",
                imageUrl: "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
            },
            {
                name: "Lindsay Walton",
                handle: "lindsaywalton",
                imageUrl: "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
            },
            {
                name: "Courtney Henry",
                handle: "courtneyhenry",
                imageUrl: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
            },
            {
                name: "Tom Cook",
                handle: "tomcook",
                imageUrl: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
            },
        ],
        totalMembers: 12,
        lastUpdated: "March 17, 2020",
        pinned: true,
        bgColorClass: "bg-green-500",
    },
];
const pinnedProjects = projects.filter((project) => project.pinned);

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export default function TaskTable(props: { data: any }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const onOpen = () => {
        setSidebarOpen(true);
    };

    const onClose = () => {
        setSidebarOpen(false);
    };

    return (
        <>
            <ProjectDrawer open={sidebarOpen} onClose={onClose} />

            <div className="min-h-full">
                {/* Static sidebar for desktop */}
                <main className="flex-1">
                    {/* Page title & actions */}

                    {/* Projects list (only on smallest breakpoint) */}
                    {/* <div className="mt-0 sm:hidden">
                        <div className="px-4 sm:px-6">
                            <h2 className="text-xs font-medium tracking-wide text-gray-500 uppercase">Projects</h2>
                        </div>
                        <ul role="list" className="mt-3 border-t border-gray-200 divide-y divide-gray-100">
                            {projects.map((project) => (
                                <li key={project.id}>
                                    <a href="#" className="flex items-center justify-between px-4 py-4 group hover:bg-gray-50 sm:px-6">
                                        <span className="flex items-center space-x-3 truncate">
                                            <span className={classNames(project.bgColorClass, "w-2.5 h-2.5 flex-shrink-0 rounded-full")} aria-hidden="true" />
                                            <span className="text-sm font-medium leading-6 truncate">
                                                {project.title} <span className="font-normal text-gray-500 truncate">in {project.team}</span>
                                            </span>
                                        </span>
                                        <ChevronRightIcon className="w-5 h-5 ml-4 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div> */}

                    {/* Projects table (small breakpoint and up) */}
                    <div className="mt-8 sm:block">
                        <div className="inline-block min-w-full align-middle border-b border-gray-200">
                            <table className="min-w-full">
                                <thead>
                                    <tr className="border-t border-gray-200">
                                        <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            <span className="lg:pl-2">Task</span>
                                        </th>
                                        <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">Owner</th>
                                        <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">Last updated</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-100">
                                    {props.data.length == 0 && (
                                        <>
                                            <tr>
                                                <td className="w-full px-0 py-3 text-sm font-medium text-gray-900 max-w-0 whitespace-nowrap">
                                                    <Skeleton />
                                                </td>
                                                <td className="px-6 py-3 text-sm text-right text-gray-500 md:table-cell whitespace-nowrap">
                                                    <Skeleton />
                                                </td>
                                                <td className="px-6 py-3 text-sm text-right text-gray-500 md:table-cell whitespace-nowrap">
                                                    <Skeleton />
                                                </td>
                                            </tr>
                                        </>
                                    )}

                                    {props.data.map((_t: { _creationTime: any; _id: any; isCompleted: any; owner: any; text: any }) => (
                                        <tr key={nanoid()}>
                                            <td className="w-full px-0 py-3 text-sm font-medium text-gray-900 max-w-0 whitespace-nowrap">
                                                <div className="flex items-center space-x-3 lg:pl-2">
                                                    <div className={classNames(_t._id, "bgColorClass flex-shrink-0 w-2.5 h-2.5 rounded-full")} aria-hidden="true" />
                                                    <a href="#" className="truncate hover:text-gray-600">
                                                        <span className={classNames(_t.isCompleted ? "line-through" : "")}>{_t.text}</span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td className="px-6 py-3 text-sm text-right text-gray-500 md:table-cell whitespace-nowrap">
                                                {_t.owner} {_t.isCompleted}{" "}
                                            </td>
                                            <td className="px-6 py-3 text-sm text-right text-gray-500 md:table-cell whitespace-nowrap">
                                                <Moment fromNow>{_t._creationTime}</Moment>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}
