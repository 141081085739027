export const employeesbreadcrumLinks = [{ name: "Employees", href: "/employees", current: true }];

export const timesheetBreadcrums = [{ name: "Timesheet", href: "/timesheet", current: true }];

export const timesheetDetailsBreadcrums = [
    { name: "Timesheet", href: "/timesheet", current: false },
    { name: "Timesheet Details", href: "#", current: true },
];

export const payrollBreadcrums = [{ name: "Payroll", href: "/payroll", current: true }];

export const payrollMonthlyReportBreadcrums = [
    { name: "Payroll", href: "/payroll", current: false },
    { name: "Monthly Snapshot Report", href: "/payroll/month", current: true },
];

export const paymentsBreadcrums = [{ name: "Payments", href: "/payments", current: true }];

export const paymentsGenerateInvoiceBreadcrums = [
    { name: "Payments", href: "/payments", current: false },
    { name: "Invoices", href: "/payments/invoice", current: true },
];

export const paymentsAdjustmentsBreadcrums = [
    { name: "Payments", href: "/payments", current: false },
    { name: "Billing Adjustments", href: "/payments/adjust", current: true },
];

export const paymentsBulkBreadcrums = [
    { name: "Payments", href: "/payments", current: false },
    { name: "Bulk Payments", href: "/payments/bulk", current: true },
];

export const resourceAccountRecievableBreadcrums = [
    { name: "Payments", href: "/payments", current: false },
    { name: "Account Receivable Statement", href: "#", current: true },
];

export const ikatAccountRecievableBreadcrums = [
    { name: "Payments", href: "/payments", current: false },
    { name: "Account Receivable Statement", href: "/payments/receivable/user", current: false },
    { name: "Ikat Account Receivable Statement", href: "#", current: true },

];



export const monthlyAccountRecievableBreadcrums = [
    { name: "Payments", href: "/payments", current: false },
    { name: "Monthly Account Receivable Statement", href: "#", current: true },
];

export const outstandingPaymentsBreadcrums = [
    { name: "Payments", href: "/payments", current: false },
    { name: "Outstanding Payments", href: "#", current: true },
];
