import FullCalendar from "@fullcalendar/react";
import React from "react";

import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import listPlugin from "@fullcalendar/list";

import moment from "moment";

export default function FinanceCalendar(props: { events: any; onDateClick }) {
    return (
        <div>
            {/* dateClick={props.onDateClick} */}
            {/* dayGridDay height={520} */}
            <FullCalendar showNonCurrentDates={false} contentHeight={400} dayMaxEventRows={true} events={props.events} dayMaxEvents={true} plugins={[dayGridPlugin, interactionPlugin, listPlugin]} headerToolbar={{ start: "today prev next", center: "title", end: "dayGridMonth dayGridWeek listWeek" }} initialView="dayGridMonth" hiddenDays={[]} weekends={true} eventClick={props.onDateClick} displayEventTime={false} validRange={{ end: moment().endOf("month").toDate() }} />
        </div>
    );
}
