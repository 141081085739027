import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { CalendarIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, SearchIcon, UsersIcon } from "@heroicons/react/solid";
import { MenuAlt1Icon, XIcon } from "@heroicons/react/outline";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const navigation = [
    { name: "Profile", href: "#", icon: HomeIcon, current: true },
    { name: "Qualifications", href: "#", icon: InboxIcon, current: true },
    { name: "Experiences", href: "#", icon: ChartBarIcon, current: true },
    { name: "Project Details", href: "#", icon: FolderIcon, current: true },
    { name: "Documents", href: "#", icon: FolderIcon, current: false },
    // { name: "Projects", href: "#", icon: FolderIcon, current: false },
    // { name: "Calendar", href: "#", icon: CalendarIcon, current: false },
    // { name: "Documents", href: "#", icon: InboxIcon, current: false },
    // { name: "Reports", href: "#", icon: ChartBarIcon, current: false },
];
const secondaryNavigation = [
    { name: "Payroll", href: "#" },
    { name: "Leaves", href: "#" },
    // { name: "Customer migration guides", href: "#" },
    // { name: "Profit sharing program", href: "#" },
];

export default function TheEmployee() {
    return (
        <>
            {/* Background color split screen for large screens */}
            {/* <div className="fixed top-0 left-0 w-1/2 h-full bg-white" aria-hidden="true" />
            <div className="fixed top-0 right-0 w-1/2 h-full bg-gray-50" aria-hidden="true" /> */}
            <div className="relative flex flex-col min-h-screen">
                {/* 2 column wrapper */}
                <div className="flex-grow w-full max-w-full mx-auto xl:px-2 lg:flex">
                    {/* Left sidebar & main wrapper */}
                    <div className="flex-1 min-w-0 bg-white-x xl:flex">
                        <div className="border-b border-gray-100 bg-white-x xl:border-b-0 xl:flex-shrink-0 xl:w-64 xl:border-r xl:border-gray-200">
                            <div className="h-full py-6 pl-4 pr-6 sm:pl-6 lg:pl-8 xl:pl-0">
                                <div className="relative h-full mt-16" style={{ minHeight: "12rem" }}>
                                    {/* Navigation Code */}

                                    <nav aria-label="Sidebar">
                                        <h3 className="px-3 text-xs font-semibold tracking-wider text-gray-500 uppercase" id="projects-headline">
                                            Menu
                                        </h3>
                                        <div className="space-y-1">
                                            {navigation.map((item) => (
                                                <a key={item.name} href={item.href} className={classNames(item.current ? "bg-gray-100 text-gray-900" : "text-gray-600 hover:bg-gray-50 hover:text-gray-900", "group flex items-center px-3 py-2 text-sm font-medium rounded-md")} aria-current={item.current ? "page" : undefined}>
                                                    <item.icon className={classNames(item.current ? "text-gray-500" : "text-gray-400 group-hover:text-gray-500", "flex-shrink-0 -ml-1 mr-3 h-6 w-6")} aria-hidden="true" />
                                                    <span className="truncate">{item.name}</span>
                                                </a>
                                            ))}
                                        </div>
                                        <div className="mt-8">
                                            <h3 className="px-3 text-xs font-semibold tracking-wider text-gray-500 uppercase" id="projects-headline">
                                                Actions
                                            </h3>
                                            <div className="mt-1 space-y-1" aria-labelledby="projects-headline">
                                                {secondaryNavigation.map((item) => (
                                                    <a key={item.name} href={item.href} className="flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md group hover:text-gray-900 hover:bg-gray-50">
                                                        <span className="truncate">{item.name}</span>
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white-x lg:min-w-0 lg:flex-1">
                            <div className="h-full px-4 py-6 sm:px-6 lg:px-8">
                                {/* Start main area*/}
                                <div className="relative h-full" style={{ minHeight: "36rem" }}>
                                    <div className="absolute inset-0 border-2 border-gray-200 border-dashed rounded-lg" />
                                </div>
                                {/* End main area */}
                            </div>
                        </div>
                    </div>

                    {/* <div className="pr-4 bg-gray-50 sm:pr-6 lg:pr-8 lg:flex-shrink-0 lg:border-l lg:border-gray-200 xl:pr-0">
                        <div className="h-full py-6 pl-6 lg:w-80">
                            <div className="relative h-full" style={{ minHeight: "16rem" }}>
                                <div className="absolute inset-0 border-2 border-gray-200 border-dashed rounded-lg" />
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
}
