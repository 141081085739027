import React from "react";

export default function HeroSections() {
    return (
        <section className="py-12 bg-white sm:py-16 lg:py-20">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 text-center gap-y-10 md:grid-cols-3 md:text-left">
                    <div className="md:pr-6 lg:pr-12">
                        <h3 className="mt-12 text-lg font-bold text-gray-900 font-pj">Experience and Engagement</h3>
                        <p className="mt-5 text-base text-gray-600 font-pj">Bringing operations to life for IT and IT-enabled Services</p>
                    </div>

                    <div className="w-56 h-px mx-auto bg-gray-200 md:hidden"></div>

                    <div className="md:px-6 lg:px-12 md:border-l md:border-gray-200">
                        <h3 className="mt-12 text-lg font-bold text-gray-900 font-pj">Operational Board</h3>
                        <p className="mt-5 text-base text-gray-600 font-pj">To combine people and technology in a well-integrated platform</p>
                    </div>

                    <div className="w-56 h-px mx-auto bg-gray-200 md:hidden"></div>

                    <div className="md:pl-6 lg:pl-12 md:border-l md:border-gray-200">
                        <h3 className="mt-12 text-lg font-bold text-gray-900 font-pj">24//7 Customer Support</h3>
                        <p className="mt-5 text-base text-gray-600 font-pj">Need service or support? Start your request online and we’ll find you a solution.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}
