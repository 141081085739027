/* This example requires Tailwind CSS v2.0+ */
import { Fragment, createContext, useState } from "react"; //useContext
import { Dialog, Transition } from "@headlessui/react";
import { ChartBarIcon, CogIcon, CurrencyDollarIcon, FolderIcon, HomeIcon, InboxIcon, MenuIcon, QuestionMarkCircleIcon, UsersIcon, XIcon, BankNotesIcon, DocumentIcon } from "@heroicons/react/outline"; //CalendarIcon,
import { useAuth0 } from "@auth0/auth0-react";
// import { useCookies } from "react-cookie";
// import UserContext from "../components/TheUser";
import { Outlet, useLocation } from "react-router";

import { Menu } from "@headlessui/react";
import { ClockIcon, MenuAlt1Icon, ViewListIcon } from "@heroicons/react/outline";
import { ChevronRightIcon, DotsVerticalIcon, FilterIcon, SearchIcon, SelectorIcon } from "@heroicons/react/solid";
// import { useRouter } from "next/router";

const navigation = [
    { name: "Dashboard", href: "/dashboard", icon: HomeIcon, current: false },
    {
        name: "Employees",
        href: "/employees",
        icon: UsersIcon,
        current: false,
        count: 0,
    },
    // {
    //     name: "Projects",
    //     href: "/projects",
    //     icon: FolderIcon,
    //     current: false,
    //     count: 0,
    // },
    // { name: 'Calendar', href: '#', icon: CalendarIcon, current: false },

    { name: "Timesheet", href: "/timesheet", icon: ClockIcon, current: false },

    { name: "Payroll", href: "/payroll", icon: DocumentIcon, current: false },

    { name: "Payments", href: "/payments/invoice", icon: CurrencyDollarIcon, current: false },

    // { name: "Reports", href: "/reports", icon: ChartBarIcon, current: false },
];

const secondaryNavigation = [
    // { name: "Tasks", href: "/tasks" },
    { name: "Settings", href: "/settings", icon: CogIcon },
    { name: "Help", href: "/help", icon: QuestionMarkCircleIcon },
    // { name: 'Customer migration guides', href: '#' },
    // { name: 'Profit sharing program', href: '#' },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function DefaultLayout() {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    // const router = useRouter();

    const location = useLocation();

    const { isAuthenticated, user, loginWithRedirect, logout, loginWithPopup } = useAuth0();

    // const [cookies, setCookie] = useCookies(["auth0"]);

    // const userData = UserContext(UserContext);

    return (
        <>
            {/* {userInContext.name} */}
            {/* {Object.keys(cookies).length > 0 && ( */}
            <div className="h-full w-max-1xl no-scrollbar">
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="fixed inset-0 z-40 flex md:hidden" onClose={setSidebarOpen}>
                        <Transition.Child as={Fragment} enter="transition-opacity ease-linear duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity ease-linear duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                        </Transition.Child>
                        <Transition.Child as={Fragment} enter="transition ease-in-out duration-300 transform" enterFrom="-translate-x-full" enterTo="translate-x-0" leave="transition ease-in-out duration-300 transform" leaveFrom="translate-x-0" leaveTo="-translate-x-full">
                            <div className="relative flex flex-col flex-1 w-full max-w-xs bg-white">
                                <Transition.Child as={Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                                    <div className="absolute top-0 right-0 pt-2 -mr-12">
                                        <button type="button" className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" onClick={() => setSidebarOpen(false)}>
                                            <span className="sr-only">Close sidebar</span>
                                            <XIcon className="w-6 h-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                                    <div className="flex items-center flex-shrink-0 px-4">
                                        <img className="w-auto h-20 mx-auto" src="/mti-logo.png" alt="MTINC" />
                                    </div>
                                    <nav className="px-2 mt-5 space-y-1">
                                        {navigation.map((item) => (
                                            <a key={item.name} href={item.href} className={classNames(location.pathname.startsWith(item.href) ? "bg-gray-100 text-gray-900" : "text-gray-600 hover:bg-gray-50 hover:text-gray-900", "group flex items-center px-2 py-2 text-base font-medium rounded-md")}>
                                                <item.icon className={classNames(location.pathname.startsWith(item.href) ? "text-gray-500" : "text-gray-400 group-hover:text-gray-500", "mr-4 flex-shrink-0 h-6 w-6")} aria-hidden="true" />
                                                <span className="truncate">{item.name}</span>
                                                {item.count ? <span className={classNames(location.pathname.startsWith(item.href) ? "bg-gray-50" : "bg-gray-200 text-gray-600", "ml-auto inline-block py-0.5 px-3 text-xs rounded-full")}>{item.count}</span> : null}
                                            </a>
                                        ))}
                                    </nav>

                                    {secondaryNavigation.length > 0 && (
                                        <div className="pt-0 mt-5">
                                            <h3 className="px-3 text-xs font-semibold tracking-wider text-gray-500 uppercase" id="projects-headline">
                                                Quick Links
                                            </h3>
                                            <div className="mt-1 space-y-1" aria-labelledby="projects-headline">
                                                {secondaryNavigation.map((item) => (
                                                    <a key={item.name} href={item.href} className={classNames("flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md group hover:text-gray-900 hover:bg-gray-50", location.pathname.startsWith(item.href) ? "bg-gray-50" : "g-gray-200 text-gray-600")}>
                                                        {/* <item.icon
                                                      className={classNames(
                                                        item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                        'mr-3 flex-shrink-0 h-6 w-6'
                                                      )}
                                                      aria-hidden="true"
                                                    /> */}
                                                        <span className="truncate">{item.name}</span>
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="flex flex-shrink-0 p-4 border-t border-gray-200">
                                    <a href="#" className="flex-shrink-0 block group">
                                        <div className="flex items-center">
                                            {/* <div>
                                                <img className="inline-block w-10 h-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                                            </div> */}
                                            <div className="pr-16 ml-0">
                                                {/* <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">Adminstrator</p>
                                                <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">View profile</p> */}
                                                <p className="text-sm font-bold text-gray-900 truncate ">{user?.name || "Administrator"}</p>
                                                <p className="text-xs text-gray-500 truncate">{user?.email || "Login Required"}</p>
                                            </div>
                                            <SelectorIcon className="flex-shrink-0 float-right w-5 h-5 text-gray-400 group-hover:text-gray-500 " aria-hidden="true" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </Transition.Child>
                        <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex flex-col flex-1 min-h-0 bg-white border-r border-gray-200">
                        <div className="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
                            <div className="flex items-center flex-shrink-0 px-4">
                                <img className="w-auto h-20 mx-auto" src="/mti-logo.png" alt="MTINC" />
                            </div>
                            <nav className="flex-1 px-2 mt-5 space-y-1 bg-white">
                                <h3 className="px-3 text-xs font-semibold tracking-wider text-gray-500 uppercase" id="projects-headline">
                                    Navigation
                                </h3>
                                {navigation.map((item) => (
                                    <a key={item.name} href={item.href} className={classNames(location.pathname.startsWith(item.href) ? "bg-gray-100 text-gray-900" : "text-gray-600 hover:bg-gray-50 hover:text-gray-900", "group flex items-center px-2 py-2 text-sm font-medium rounded-md")}>
                                        <item.icon className={classNames(location.pathname.startsWith(item.href) ? "text-gray-500" : "text-gray-400 group-hover:text-gray-500", "mr-3 flex-shrink-0 h-6 w-6")} aria-hidden="true" />
                                        <span className="truncate">{item.name}</span>
                                        {item.count ? <span className={classNames(location.pathname.startsWith(item.href) ? "bg-gray-50" : "bg-gray-200 text-gray-600", "ml-auto inline-block py-0.5 px-3 text-xs rounded-full")}>{item.count}</span> : null}
                                    </a>
                                ))}

                                {secondaryNavigation.length > 0 && (
                                    <div className="pt-5 mt-10">
                                        <h3 className="px-3 text-xs font-semibold tracking-wider text-gray-500 uppercase" id="projects-headline">
                                            Quick Links
                                        </h3>
                                        <div className="mt-1 space-y-1" aria-labelledby="projects-headline">
                                            {secondaryNavigation.map((item) => (
                                                <a key={item.name} href={item.href} className={classNames("flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md group hover:text-gray-900 hover:bg-gray-50", location.pathname.startsWith(item.href) ? "bg-gray-50" : "g-gray-200 text-gray-600")}>
                                                    {/* <item.icon
                                                      className={classNames(
                                                        item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                        'mr-3 flex-shrink-0 h-6 w-6'
                                                      )}
                                                      aria-hidden="true"
                                                    /> */}
                                                    <span className="truncate">{item.name}</span>
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                <div className="pt-5 mt-10">
                                    {/* {
                                        // Not
                                        <a onClick={() => loginWithRedirect()} className="flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md group hover:text-gray-900 hover:bg-gray-50">
                                            <span className="truncate">Login</span>
                                        </a>
                                    } */}

                                    {/* {
                                        // yes
                                        <a
                                            onClick={() =>
                                                logout({
                                                    returnTo: window.location.origin,
                                                })
                                            }
                                            className="flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md group hover:text-gray-900 hover:bg-gray-50"
                                        >
                                            <span className="truncate">Logout</span>
                                        </a>
                                    } */}
                                </div>
                            </nav>
                        </div>

                        {/* Sidebar component, swap this element with another sidebar if you like */}
                        <div className="absolute inset-x-0 bottom-0 flex flex-col flex-1 flex-shrink-0">
                            {/* User account dropdown */}
                            <Menu as="div" className="relative flex flex-shrink-0 inline-block px-3 text-left border-t border-gray-200">
                                <div>
                                    <Menu.Button className="group w-full bg-white rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 focus:outline-none ">
                                        <span className="flex items-center justify-between w-full">
                                            <span className="flex items-center justify-between min-w-0 space-x-3">
                                                {/* <img className="flex-shrink-0 w-10 h-10 bg-gray-300 rounded-full" src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80" alt="" /> */}
                                                <span className="flex flex-col flex-1 min-w-0 pr-5 ml-0">
                                                    <span className="text-sm font-bold text-gray-900 truncate">{user?.name || "Administrator"}</span>
                                                    <span className="text-xs text-gray-500 truncate">{user?.email || "Login Required"}</span>
                                                </span>
                                            </span>
                                            <SelectorIcon className="flex-shrink-0 w-5 h-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                        </span>
                                    </Menu.Button>
                                </div>
                                <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                    <Menu.Items className="absolute left-0 right-0 z-10 mx-0 mt-1 origin-bottom transform -translate-y-full divide-y divide-gray-200 rounded-md shadow-lg bg-gray-50 -top-2 ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {/* absolute left-0 right-0 z-10 mx-3 mt-1 origin-bottom bg-white divide-y divide-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none */}
                                        {/*  absolute right-0 w-56 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none */}
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a href="/profile" className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
                                                        View profile
                                                    </a>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a href="/settings" className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
                                                        Settings
                                                    </a>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a href="/alerts" className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
                                                        Notifications
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </div>
                                        <div className="py-1">
                                            {/* <Menu.Item>
                                                {({ active }) => (
                                                    <a href="#" className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
                                                        Get desktop app
                                                    </a>
                                                )}
                                            </Menu.Item> */}
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a href="/support" className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
                                                        Support
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </div>
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        onClick={() =>
                                                            logout({
                                                                returnTo: window.location.origin,
                                                            })
                                                        }
                                                        href="#"
                                                        className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}
                                                    >
                                                        Logout
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>

                        {/* <div className="flex flex-shrink-0 p-4 border-t border-gray-200">
                            <a href="#" className="flex-shrink-0 block w-full group">
                                <div className="flex items-center">
                                    <div>
                                        <img className="inline-block rounded-full h-9 w-9" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-sm font-bold text-gray-700 group-hover:text-gray-900">{user?.name || "Administrator"}</p>
                                        <p className="text-xs font-medium text-gray-400 group-hover:text-gray-500">{user?.email || "Login Required"}</p>
                                    </div>
                                </div>
                            </a>
                        </div> */}
                    </div>
                </div>
                <div className="flex flex-col flex-1 md:pl-64">
                    <div className="sticky top-0 z-10 pt-1 pl-1 bg-white md:hidden sm:pl-3 sm:pt-3">
                        <button type="button" className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" onClick={() => setSidebarOpen(true)}>
                            <span className="sr-only">Open sidebar</span>
                            <MenuIcon className="w-6 h-6" aria-hidden="true" />
                        </button>
                    </div>

                    <div className="h-full min-h-screen py-0 pb-10 bg-gray-100">
                        <Outlet />
                    </div>
                </div>
            </div>
            {/* )} */}

            {/* {!Object.keys(cookies).length > 0 && (
                <div className="h-full min-h-screen py-0 bg-gray-100 ">
                    <Outlet />
                </div>
            )} */}
        </>
    );
}
