import React, { useState } from "react";
import Breadcrums from "../components/breadcrums";
import classNames from "classnames";
import { Link } from "react-router-dom";
import PageTitle from "../components/pageTitle";
import { payrollBreadcrums } from "../constants/breadcrums";
import { payrollCommandActions, button, buttonBase, alternativeBgClr, blueBgClr, sectionClass } from "../constants/common";
import { nanoid } from "nanoid";

export default function ImportPayroll() {
    const [file, setFile] = useState();
    const [array, setArray] = useState([]);

    const fileReader = new FileReader();

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };

    const csvFileToArray = (string) => {
        const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

        const array = csvRows.map((i) => {
            const values = i.split(",");
            const obj = csvHeader.reduce((object, header, index) => {
                object[header] = values[index];
                return object;
            }, {});
            return obj;
        });

        setArray(array);
    };

    function handleOnSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();

        console.log("Importing ...");

        if (file) {
            fileReader.onload = function (event: any) {
                const text = event.target.result;
                csvFileToArray(text);
            };

            fileReader.readAsText(file);
        }
    }

    const headerKeys = Object.keys(Object.assign({}, ...array));

    return (
        <>
            <div>
                <Breadcrums breadcrumLinks={payrollBreadcrums} commands={payrollCommandActions} module={"Payroll"} groups={["Actions", "Statement"]} />
                <PageTitle title={"Import Payroll"} preSummary={""} summary={"Import Payroll historical details."}></PageTitle>
            </div>

            <section className={sectionClass}>
                <form>
                    <input type={"file"} id={"csvFileInput"} accept={".csv"} onChange={handleOnChange} />

                    <button
                        onClick={(e) => {
                            handleOnSubmit(e);
                        }}
                        className={classNames(button, buttonBase, blueBgClr)}
                    >
                        Import CSV File
                    </button>
                </form>

                <br />
            </section>

            <section>
                <table>
                    <thead>
                        <tr key={"header"}>
                            {headerKeys.map((key) => (
                                <th key={nanoid()}>{key}</th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {array.map((item) => (
                            <tr key={nanoid()}>
                                {Object.values(item).map((val: any) => (
                                    <td>{val}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
        </>
    );
}
