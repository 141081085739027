import React, { useState } from "react";

import { add, eachDayOfInterval, endOfMonth, format, getDay, isEqual, isSameDay, isThisMonth, isToday, parse, parseISO, startOfToday, getWeek } from "date-fns";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import CalendarDate from "../blocks/calendardate";
import classNames from "classnames";
import { getFinances } from "../api/finance-api";
import { getProfileByUUID } from "../api/consultants-api";
import { nanoid } from "nanoid";

export default function TimesheetCalendar(props: { uuid: any }) {
    const [profile, setProfile] = useState({
        name: "",
        avatar: "",
        designation: "",
        email: "",
        uuid: props.uuid,
        project: "",
    });

    let today = startOfToday();
    let [selectedDay, setSelectedDay] = useState(today);
    let [currentMonth, setCurrentMonth] = useState(format(today, "MMM-yyyy"));
    let firstDayCurrentMonth = parse(currentMonth, "MMM-yyyy", new Date());
    let [monthFinance, setMonthFinance] = useState<any>([]);
    let [isDataSaved, setisDataSaved] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    let days = eachDayOfInterval({
        start: firstDayCurrentMonth,
        end: endOfMonth(firstDayCurrentMonth),
    });

    function previousMonth() {
        let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
        setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
        setMonthFinance([]);
        getFinances(firstDayNextMonth?.getMonth() + 1, firstDayNextMonth?.getFullYear(), profile?.uuid, profile?.project, setIsLoading).then((data) => {
            setIsLoading(false);
            setMonthFinance(data.length === 0 ? [] : data);
        });
    }

    function nextMonth() {
        let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
        setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
        setMonthFinance([]);

        getFinances(firstDayNextMonth?.getMonth() + 1, firstDayNextMonth.getFullYear(), profile?.uuid, profile?.project, setIsLoading).then((data) => {
            setIsLoading(false);
            setMonthFinance(data);
        });
    }

    function getHrs(day) {
        return monthFinance?.length === 0 ? null : monthFinance?.find((e) => e.day === new Date(day).getDate())?.hrs;
    }

    let colStartClasses = ["", "col-start-2", "col-start-3", "col-start-4", "col-start-5", "col-start-6", "col-start-7"];

    function getCount(day) {
        let date = getWeek(day);

        let dates = [...monthFinance.filter((e) => e.week === date)];

        let count = 0;
        dates?.forEach((a) => {
            try {
                count += a?.hrs || 0;
            } catch (e) {}
        });
        return count;
    }

    // let selectedDayMeetings = meetings.filter((meeting) => isSameDay(parseISO(meeting.startDatetime), selectedDay));

    React.useEffect(() => {
        let profileInSession = { uuid: props.uuid, project: "" }; //FIXME

        if (profileInSession && profileInSession.uuid && selectedDay) {
            // setProfile(profileInSession); FIXME
            if (selectedDay) {
                getFinances(selectedDay?.getMonth() + 1, selectedDay?.getFullYear(), profileInSession?.uuid, profileInSession?.project, setIsLoading).then((data) => {
                    setIsLoading(false);
                    setMonthFinance(data.length === 0 ? [] : data);
                });
            }
        } else {
            getProfileByUUID(props.uuid).then((data) => {
                sessionStorage.setItem(props.uuid, JSON.stringify(data[0]));
                setProfile(data[0]);

                if (selectedDay) {
                    getFinances(selectedDay?.getMonth() + 1, selectedDay?.getFullYear(), profile?.uuid, profile?.project, false).then((data) => {
                        setIsLoading(false);
                        setMonthFinance(data.length === 0 ? [] : data);
                    });
                }
            });
        }
    }, [props.uuid]);

    return (
        <>
            <div className="mt-10 text-center lg:col-start-6 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-7">
                <div className="flex items-center text-gray-900">
                    <button type="button" onClick={previousMonth} className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Previous month</span>
                        <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                    <div className="flex-auto font-semibold">{format(firstDayCurrentMonth, "MMMM yyyy")}</div>
                    <button type="button" onClick={nextMonth} className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Next month</span>
                        <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                </div>
                <div className="grid grid-cols-8 mt-6 text-xs leading-6 text-gray-500">
                    <div key={nanoid()}>Sun</div>
                    <div key={nanoid()}>Mon</div>
                    <div key={nanoid()}>Tue</div>
                    <div key={nanoid()}>Wed</div>
                    <div key={nanoid()}>Thu</div>
                    <div key={nanoid()}>Fri</div>
                    <div key={nanoid()}>Sat</div>
                    <div key={nanoid()}></div>
                </div>

                {!isLoading && (
                    <div className="grid grid-cols-8 gap-px mt-2 text-sm bg-gray-200 rounded-lg shadow isolate ring-1 ring-gray-200">
                        {days.map((day, dayIdx) => (
                            <React.Fragment key={nanoid()}>
                                <div key={day.toString()} className={classNames(dayIdx === 0 && colStartClasses[getDay(day)])}>
                                    <CalendarDate
                                        day={{
                                            date: day, //getDate(day),
                                            isCurrentMonth: isThisMonth(day),
                                            isToday: isToday(day),
                                            isWeekend: day.getDay() === 0 || day.getDay() === 6,
                                            hours: getHrs(day),
                                        }}
                                        index={dayIdx}
                                        days={days}
                                        hours={getHrs(day)}
                                        onSelectDay={setSelectedDay}
                                        isDataSaved={setisDataSaved}
                                        key={nanoid()}
                                    />
                                </div>
                                {(getDay(day) % 7 == 6 || days.length - 1 === dayIdx) &&
                                    (() => {
                                        const arr: any = [];
                                        let dayOfWeek = getDay(day);

                                        for (let i = dayOfWeek; i < 7; i++) {
                                            if (days.length - 1 === dayIdx) {
                                                arr.push(
                                                    <span key={dayIdx} className="flex flex-col items-center justify-center pt-10 mx-auto font-semibold rounded-full h-7 w-7">
                                                        <div></div>
                                                        <div>{i === 6 ? getCount(day) + "h" : ""} </div>
                                                    </span>
                                                );
                                            } else {
                                                arr.push(
                                                    <span key={dayIdx} className="flex flex-col items-center justify-center pt-10 mx-auto font-semibold rounded-full h-7 w-7">
                                                        <div></div>
                                                        <div>{getCount(day)}h</div>
                                                    </span>
                                                );
                                            }
                                        }
                                        return arr;
                                    })()}
                            </React.Fragment>
                        ))}
                    </div>
                )}
                {/* <button
            type="button"
            className="w-full px-4 py-2 mt-8 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow focus:outline-none hover:bg-blue-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            I consent, Freeze for this month
          </button> */}

                <p className="pt-5 text-xs text-left text-gray-500">
                    Kindly ensure precision in your submissions through the MTOps app before the{" "}
                    <b>
                        26<sup>th</sup> of each month
                    </b>{" "}
                    . If you have any questions or concerns, please feel free to reach out to our dedicated{" "}
                    <a href="mailto:support@mindtechinc.com" className="font-bold text-blue-500">
                        PMO CEO support desk.
                    </a>{" "}
                    for assistance.
                </p>
            </div>
        </>
    );
}
