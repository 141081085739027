import React, { useEffect, useState } from "react";
import Breadcrums from "../../../components/breadcrums";
import PageTitle from "../../../components/pageTitle";
import { paymentsGenerateInvoiceBreadcrums } from "../../../constants/breadcrums";
import { paymentsCommands, sectionClass } from "../../../constants/common";
import CommonFilters from "../../../blocks/commonFilters";
import { useSnackbar } from "react-simple-snackbar";
import { listOfEmployees } from "../../../api/consultants-api";
import TanstackTable from "../../../components/TanstackTable";
import { ColumnDef } from "@tanstack/react-table";
import { generateInvoice, generateWeeklyInvoice, getInvoicesData } from "../../../api/finance-api";
import Currency from "react-currency-formatter";
import moment from "moment";
// import { generate } from "@pdfme/generator";
import { FinanceRecord, InvoiceRequestParams } from "../../../model";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
// import ToolTip from "../../../components/ToolTip";

import { NumericFormat } from 'react-number-format';


import nextId, { useId } from "react-id-generator";

import { Button, Tooltip } from "flowbite-react";

export default function Invoices() {
    const [people, setPeople] = useState([]);
    const [openSnackbar] = useSnackbar();
    const [isLoading, setLoading] = React.useState(false);

    const [data, setData] = React.useState([]);

    useEffect(() => {
        setLoading(true);

        setPeople([]);
        setData([]);

        getInvoicesData({
            checkDate: new Date(),
            employee: "",
        }).then((data) => {
            // console.log(data);
            setData(data);
        });

        listOfEmployees()
            .then((data) => setPeople(data))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    function applyFilters(filters): any {
        console.log(filters);

        setLoading(true);
        setData([]);

        getInvoicesData(filters)
            .then((data) => {
                console.log(data);
                setData(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function generateInvoiceHandler(params: InvoiceRequestParams): any {
        console.log(params);

        // params.invoiceNo = new Date().getTime(); //FIXME

        if (params.billingPeriod == "Monthly" || params.billingPeriod == "Monthly Itemized") {
            params.invoiceNo = nextId("INV" + moment(params.checkDate).format("YYMMDD-"));
            console.log(params.invoiceNo);

            generateInvoice(params).then((r) => {
                console.log(r);
                if (params.uuid) {
                    openSnackbar("Invoice Generated");
                } else {
                    openSnackbar("Invoices Generated");
                }
            });
        } else if (params.billingPeriod == "Weekly") {
            params.invoiceNo = nextId("INV" + moment(params.checkDate).format("YYMMDD-"));
            console.log(params.invoiceNo);

            generateWeeklyInvoice(params).then((r) => {
                console.log(r);
                if (params.uuid) {
                    openSnackbar("Weekly Invoice(s) Generated");
                } else {
                    openSnackbar("Weekly Invoice(s) Generated");
                }
            });
        }
    }

    const columns = React.useMemo<ColumnDef<FinanceRecord, any>[]>(
        () => [
            {
                accessorKey: "name",
                header: "Name",
                // cell: (info) => info.getValue(),
                cell: ({ row }) => (
                    <>
                        <span className="font-bold">{row.original.name}</span>
                        {" (" + row.original.pid + ")"}
                    </>
                ),
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
                meta: {
                    cellClassName: "font-bold",
                    className: "font-bold",
                },
                footer: ({ table }) => (
                    <>
                        <span className="font-semibold text-md">Total</span>
                    </>
                ),
            },
            // {
            //     accessorKey: "billing_period",
            //     header: "Billing Period",
            //     cell: (info) => info.getValue(),
            //     enableColumnFilter: false,
            //     enableGrouping: false,
            //     // enableSorting: true,
            //     footer: ({ table }) => (
            //         <>
            //             <span className="font-semibold text-md">Total</span>
            //         </>
            //     ),
            // },
            // {
            //     accessorKey: "pid",
            //     header: "Project",
            //     cell: (info) => info.getValue(),
            //     enableColumnFilter: false,
            //     enableGrouping: false,
            //     // enableSorting: true,
            //     footer: ({ table }) => (
            //         <>
            //             <span className="font-semibold text-md">Total</span>
            //         </>
            //     ),
            // },
            {
                accessorKey: "invoice_no",
                header: "Invoice No.",
                cell: (info) => info.getValue() || "-",
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
                footer: ({ table }) => <>{/* <span className="font-semibold text-md">Total</span> */}</>,
            },
            {
                accessorKey: "month",
                header: "Invoice Period",
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
                footer: ({ table }) => <></>,
            },

            // {
            //     accessorKey: "_d",
            //     header: "D",
            //     cell: (info) => info.getValue(),
            //     enableColumnFilter: false,
            //     enableGrouping: false,
            //     // enableSorting: true,
            //     footer: ({ table }) => <></>,
            // },

            // {
            //     accessorKey: "billing_period",
            //     header: "Invoice Period",
            //     cell: ({ row }) => (
            //         <>
            //             {row.original.billing_period != "Weekly" && moment(row.original._d).format("MMM-YYYY")}

            //             {row.original.billing_period == "Weekly" && row.original.invoice_no == "" && moment(row.original._d).format("MMM-YYYY")}
            //             {row.original.billing_period == "Weekly" && row.original.invoice_no != "" && "Week-" + moment(row.original._d).week()}
            //         </>
            //     ),
            //     enableColumnFilter: false,
            //     enableGrouping: false,
            //     // enableSorting: true,
            //     footer: ({ table }) => <>{/* <span className="font-semibold text-md">Total</span> */}</>,
            // },
            {
                accessorKey: "rate",
                header: "Rate (USD)",
                cell: (info) => <Currency quantity={Number(info.getValue()) || 0} currency="USD" />,
                enableGrouping: false,
                enableColumnFilter: false,
                meta: {
                    cellClassName: "text-right",
                },
            },
            {
                accessorKey: "hrs",
                header: "Efforts (Hrs)",
                cell: (info) => Number(info.getValue()) | 0,
                enableGrouping: false,
                enableColumnFilter: false,
                meta: {
                    cellClassName: "text-right",
                },
                footer: ({ table }) => (
                    <>
                        {/* <span className="text-xs font-normal">Total Efforts</span>
                        <br /> */}
                        {/* <span className="text-md thousand-sepera">{table.getFilteredRowModel().rows.reduce((total, row) => total + row.original.hrs, 0)}</span> */}
                        {/* <Currency quantity={Number(table.getFilteredRowModel().rows.reduce((total, row) => total + row.original.hrs, 0)) || 0}  displayType={'text'}  /> */}
                        <NumericFormat value={Number(table.getFilteredRowModel().rows.reduce((total, row) => total + row.original.hrs, 0)) || 0} allowLeadingZeros displayType="text" thousandSeparator="," />



                    </>
                ),
            },
            // {
            //     accessorKey: "uuid",
            //     header: "UUID",
            //     cell: (info) => info.getValue(),
            //     // enableHiding: false,
            //     enableColumnFilter: false,
            //     enableGrouping: false,
            //     // columnVisibility: false,
            //     meta: {
            //         cellClassName: "text-center",
            //     },
            // },
            {
                accessorKey: "bill",
                header: "Bill Amount (USD)",
                cell:  ({ row }) => (
                    <>
                    {row.original.fixed_payroll && (
                        <>
                        <Currency quantity={row.original.fixed_payroll || 0} currency="USD" />
                        </>
                    )}
                    {!row.original.fixed_payroll && (
                        <>
                        <Currency quantity={row.original.bill || 0} currency="USD" />
                        </>
                    )}
                    </>
                ),
                // (info) => <Currency quantity={Number(info.getValue()) || 0} currency="USD" />,
                // enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                // columnVisibility: false,
                meta: {
                    cellClassName: "text-right",
                },
                footer: ({ table }) => (
                    <>
                        {/* <span className="text-xs font-normal">Total Bill</span>
                        <br /> */}
                        <Currency quantity={Number(table.getFilteredRowModel().rows.reduce((total, row) => total + (row.original.fixed_payroll ? Number(row.original.fixed_payroll) : row.original.bill), 0)) || 0} currency="USD" />
                    </>
                ),
            },
            {
                accessorKey: "payment",
                header: "Amount Recieved (USD)",
                cell: (info) => <Currency quantity={Number(info.getValue()) || 0} currency="USD" />,
                // enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                // columnVisibility: false,
                meta: {
                    cellClassName: "text-right",
                },
                footer: ({ table }) => (
                    <>
                        {/* <span className="text-xs font-normal">Total Payment Recieved</span>
                        <br /> */}
                        <Currency quantity={Number(table.getFilteredRowModel().rows.reduce((total, row) => total + row.original.payment, 0)) || 0} currency="USD" />
                    </>
                ),
            },
            {
                accessorKey: "diff",
                header: "Balance (USD)",
                cell:({ row }) => (
                    <>
                    {row.original.fixed_payroll && (
                        <>
                        <Currency quantity={row.original.fixed_diff || 0} currency="USD" />
                        </>
                    )}
                    {!row.original.fixed_payroll && (
                        <>
                        <Currency quantity={row.original.diff || 0} currency="USD" />
                        </>
                    )}
                    </>
                ),
                // (info) => <Currency quantity={Number(info.getValue()) || 0} currency="USD" />,
                // enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                // columnVisibility: false,
                meta: {
                    cellClassName: "text-right",
                },
                footer: ({ table }) => (
                    <>
                        {/* <span className="text-xs font-normal">Total Dues</span>
                        <br /> */}
                        <Currency quantity={Number(table.getFilteredRowModel().rows.reduce((total, row) => total + (row.original.fixed_payroll ? row.original.fixed_diff : row.original.diff), 0)) || 0} currency="USD" />
                    </>
                ),
            },
            {
                accessorKey: "uuid",
                header: "",
                cell: ({ row }) => (
                    <>
                        {/* && row.original.payment > 0 */}
                        {row.original.uuid && (
                            <>
                                {/* <a target="_blank" href={`/payments/invoice/${row.original.uuid}/${row.original.m}/${row.original.year}`} className="text-blue-600 no-underline hover:text-blue-900 no-line-through">
                                    Verify <span className="sr-only">, {row.original.consultant}</span>
                                </a>
                                {" | "} */}
                                {/* href={`/payments/invoice/${row.original.uuid}/${row.original.m}/${row.original.year}`} */}
                                {!row.original.invoice_no && (
                                    <a
                                        href="#"
                                        onClick={(e) =>
                                            generateInvoiceHandler({
                                                uuid: row.original.uuid,
                                                checkDate: new Date(moment.utc(row.original._d).format("YYYY-MM-DD HH:mm:ss")),
                                                billingPeriod: row.original.billing_period,
                                            })
                                        }
                                        className="text-blue-600 no-underline hover:text-blue-900 no-line-through"
                                    >
                                        Generate Invoice <span className="sr-only">, {row.original.uuid}</span>
                                    </a>
                                )}

                                {row.original.invoice_no && (
                                    <>
                                        {row.original.billing_period != "Weekly" && (
                                            <Tooltip content="View Invoice" style="dark" placement="left">
                                                <a target="_blank" href={`/payments/invoice/${row.original.uuid}/${row.original.m}/${row.original.year}/${row.original.invoice_no}/${row.original._d}`} className="text-blue-600 no-underline hover:text-blue-900 no-line-through">
                                                    View Invoice <span className="sr-only">, {row.original.invoice_no}</span>
                                                </a>
                                            </Tooltip>
                                        )}

                                        {row.original.billing_period == "Weekly" && (
                                            <Tooltip content="View Invoice" style="dark" placement="left">
                                                <a target="_blank" href={`/payments/invoice/${row.original.uuid}/${moment(row.original._d).week()}/${row.original.m}/${row.original.year}/${row.original.invoice_no}/${row.original._d}`} className="text-blue-600 no-underline hover:text-blue-900 no-line-through">
                                                    View Invoice <span className="sr-only">, {row.original.invoice_no}</span>
                                                </a>
                                            </Tooltip>
                                        )}
                                    </>
                                )}
                            </>
                        )}

                        {!row.original.uuid && (
                            <span className="text-center">
                                {" "}
                                <div className="block text-sm">
                                    {/*
                                     */}
                                    {/* <ToolTip tooltip="UUID Missing!"></ToolTip> */}
                                    <Tooltip content="UUID missing, please contact administrator!" style="dark" placement="left">
                                        <ExclamationCircleIcon className="w-5 h-5 text-center text-red-500" data-tooltip-target="tooltip-default" />
                                    </Tooltip>
                                </div>
                                {/* <button data-tooltip-target="tooltip-default" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Default tooltip
                                </button>
                                <div id="tooltip-default" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                    Tooltip content
                                    <div className="tooltip-arrow" data-popper-arrow></div>
                                </div> */}
                            </span>
                        )}
                    </>
                ),
                // enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                // columnVisibility: false,
                meta: {
                    cellClassName: "text-center",
                },
            },
        ],
        []
    );

    return (
        <>
            <main>
                <Breadcrums breadcrumLinks={paymentsGenerateInvoiceBreadcrums} commands={paymentsCommands} groups={["Actions", "Statement"]}></Breadcrums>
                <PageTitle title="Payment Invoice(s)" summary="turning services into value" preSummary="Sealing the deal with a payment invoice"></PageTitle>

                <section className={sectionClass}>
                    <CommonFilters people={people} applyFilters={applyFilters}></CommonFilters>

                    {/* <a
                        className="inline-flex items-center justify-center gap-2 px-3 py-2 mt-5 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                        href="#"
                        onClick={(e) =>
                            generateInvoiceHandler({
                                checkDate: moment(new Date()).toDate(), //FIXME
                            })
                        }
                    >
                        Generate Invoice
                    </a> */}

                    {!isLoading && data.length > 0 && <TanstackTable data={data} columns={columns} downloadBtn={true}></TanstackTable>}
                    {!isLoading && data.length <= 0 && <div className="my-5">No records found</div>}
                    {isLoading  && <div className="my-5">Loading... </div>}
                </section>
            </main>
        </>
    );
}
