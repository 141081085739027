import React from "react";
import EmployeeDetailsForm from "../blocks/employeeDetailsForm";
import { Link } from "react-router-dom";
import ProjectDetails from "../blocks/projectDetails";
import { useForm } from "react-hook-form";

export default function NewEmployee() {
    const { register, handleSubmit } = useForm();

    return (
        <>
            <div className="px-4 py-6 sm:px-6 lg:px-8">
                <div className="mb-10 sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        {/* <h1 className="text-xl font-semibold text-gray-900">Users</h1> */}
                        <h1 className="text-2xl font-semibold text-gray-900">Employee Details</h1>
                        <p className="mt-2 text-sm text-gray-700">A list of all the users in your account including their name, title, email and role.</p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
                </div>

                <EmployeeDetailsForm profile={{}} />

                {/* <ProjectDetails /> */}
            </div>
        </>
    );
}
