import React from "react";

interface PageTitleProps {
    title?: string;
    preSummary?: string;
    summary?: string;
    children?: any;
}

export default function PageTitle(props: PageTitleProps) {
    return (
        <>
            <section>
                <div className="px-4 py-6 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-2xl font-semibold text-gray-900">{props.title}</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                {props.preSummary && (
                                    <b>
                                        {props.preSummary} {" - "}
                                    </b>
                                )}
                                {props.summary}
                            </p>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">{props.children}</div>
                    </div>
                </div>
            </section>

            {/* <Link to="/employees/new">
        <button type="button" className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-900 border border-transparent rounded-md shadow-sm hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto">
            Add Paystub
        </button>
    </Link> */}
        </>
    );
}
