import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import React from "react";

export default function Settings() {
    const payments = [
        {
            id: 1,
            date: "1/1/2020",
            datetime: "2020-01-01",
            description: "Business Plan - Annual Billing",
            amount: "CA$109.00",
            href: "#",
        },
        // More payments...
    ];

    return (
        <>
            <main className="pb-10 mx-auto max-w-7xl lg:py-12 lg:px-8">
                {/* Payment details */}
                {/* <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                    <section aria-labelledby="payment-details-heading">
                        <form action="#" method="POST">
                            <div className="shadow sm:rounded-md sm:overflow-hidden">
                                <div className="px-4 py-6 bg-white sm:p-6">
                                    <div>
                                        <h2 id="payment-details-heading" className="text-lg font-medium leading-6 text-gray-900">
                                            Payment details
                                        </h2>
                                        <p className="mt-1 text-sm text-gray-500">Update your billing information. Please note that updating your location could affect your tax rates.</p>
                                    </div>

                                    <div className="grid grid-cols-4 gap-6 mt-6">
                                        <div className="col-span-4 sm:col-span-2">
                                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                                First name
                                            </label>
                                            <input type="text" name="first-name" id="first-name" autoComplete="cc-given-name" className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm" />
                                        </div>

                                        <div className="col-span-4 sm:col-span-2">
                                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                                Last name
                                            </label>
                                            <input type="text" name="last-name" id="last-name" autoComplete="cc-family-name" className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm" />
                                        </div>

                                        <div className="col-span-4 sm:col-span-2">
                                            <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                                Email address
                                            </label>
                                            <input type="text" name="email-address" id="email-address" autoComplete="email" className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm" />
                                        </div>

                                        <div className="col-span-4 sm:col-span-1">
                                            <label htmlFor="expiration-date" className="block text-sm font-medium text-gray-700">
                                                Expration date
                                            </label>
                                            <input type="text" name="expiration-date" id="expiration-date" autoComplete="cc-exp" className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm" placeholder="MM / YY" />
                                        </div>

                                        <div className="col-span-4 sm:col-span-1">
                                            <label htmlFor="security-code" className="flex items-center text-sm font-medium text-gray-700">
                                                <span>Security code</span>
                                                <QuestionMarkCircleIcon className="flex-shrink-0 w-5 h-5 ml-1 text-gray-300" aria-hidden="true" />
                                            </label>
                                            <input type="text" name="security-code" id="security-code" autoComplete="cc-csc" className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm" />
                                        </div>

                                        <div className="col-span-4 sm:col-span-2">
                                            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                                Country
                                            </label>
                                            <select id="country" name="country" autoComplete="country-name" className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm">
                                                <option>United States</option>
                                                <option>Canada</option>
                                                <option>Mexico</option>
                                            </select>
                                        </div>

                                        <div className="col-span-4 sm:col-span-2">
                                            <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                                                ZIP / Postal code
                                            </label>
                                            <input type="text" name="postal-code" id="postal-code" autoComplete="postal-code" className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm" />
                                        </div>
                                    </div>
                                </div>
                                <div className="px-4 py-3 text-right bg-gray-50 sm:px-6">
                                    <button type="submit" className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-800 border border-transparent rounded-md shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </section>
                </div> */}

                {/* Billing history */}
                {/* <section aria-labelledby="billing-history-heading" className="pt-16">
                    <div className="pt-6 bg-white shadow sm:rounded-md sm:overflow-hidden">
                        <div className="px-4 sm:px-6">
                            <h2 id="billing-history-heading" className="text-lg font-medium leading-6 text-gray-900">
                                Billing history
                            </h2>
                        </div>
                        <div className="flex flex-col mt-6">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div className="overflow-hidden border-t border-gray-200">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                                        Date
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                                        Description
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                                        Amount
                                                    </th>
                                   
                                                    <th scope="col" className="relative px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                                        <span className="sr-only">View receipt</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {payments.map((payment) => (
                                                    <tr key={payment.id}>
                                                        <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">
                                                            <time dateTime={payment.datetime}>{payment.date}</time>
                                                        </td>
                                                        <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{payment.description}</td>
                                                        <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{payment.amount}</td>
                                                        <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                                                            <a href={payment.href} className="text-orange-600 hover:text-orange-900">
                                                                View receipt
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </main>
        </>
    );
}
