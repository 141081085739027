import React, { useEffect, useState } from "react";
import ProjectDrawer from "../blocks/projectDrawer";
import ProjectList from "../blocks/projectList";
import ProjectTable from "../blocks/projectTable";
import Breadcrums from "../components/breadcrums";
import PageHeader from "../components/pageHeader";

const breadcrumLinks = [{ name: "Projects", href: "/projects", current: true }];

export default function Projects() {
    const [projects, setProjects] = useState([]);

    async function listOfProjects() {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/consultant/groupby?_fields=project,account,_count&_having=(_count,gt,0)?_where=(active,eq,1)");
            return await response.json();
        } catch (error) {
            return [];
        }
    }

    useEffect(() => {
        listOfProjects().then((data) => setProjects(data));
    }, []);
    return (
        <>
            <main className="">
                {/* <PageHeader breadcrumLinks={breadcrumLinks} actions={actionLinks} title={"Projects"} summary={"Project Portfolio Snapshot"} summaryLine={"Overview of Ongoing and Completed Initiatives."} /> */}

                <div className="px-4 pt-5 mx-auto sm:px-6 lg:px-8">
                    <Breadcrums breadcrumLinks={breadcrumLinks} module={"Projects"} />
                </div>

                <div className="px-4 py-4 border-b border-gray-200 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
                    <div className="flex-1 min-w-0">
                        <h1 className="text-2xl font-semibold text-gray-900">Projects</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            <b>Project Portfolio Snapshot</b> - Overview of Ongoing and Completed Initiatives.
                        </p>
                    </div>
                    <div className="flex mt-4 sm:mt-0 sm:ml-4">
                        <button type="button" className="inline-flex items-center order-1 px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:order-0 sm:ml-0">
                            Management
                        </button>
                        <button type="button" className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-900 border border-transparent rounded-md shadow-sm order-0 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:order-1 sm:ml-3">
                            Create
                        </button>
                    </div>
                </div>

                <section className="px-4 py-5 sm:p-6">
                    {/* <ProjectList /> */}
                    <ProjectTable projectList={projects} />
                </section>
            </main>
        </>
    );
}
