import React, { useEffect, useState } from "react";
import Breadcrums from "../../../components/breadcrums";
import PageTitle from "../../../components/pageTitle";
import { paymentsAdjustmentsBreadcrums } from "../../../constants/breadcrums";
import { paymentsCommands, sectionClass } from "../../../constants/common";
import { listOfEmployees } from "../../../api/consultants-api";
import { getFinanceReportData } from "../../../api/finance-api";
import CommonFilters from "../../../blocks/commonFilters";
import { useSnackbar } from "react-simple-snackbar";
import Currency from "react-currency-formatter";
import moment from "moment";
import { ColumnDef } from "@tanstack/react-table";
import { FinanceRecord, InvoiceRequestParams } from "../../../model";
import TanstackTable from "../../../components/TanstackTable";
import _ from "lodash";
import ChangeRateModal from "../../../blocks/changeRateModal";

import { createModal } from "react-prompt-modal";

export default function AdjustPayments() {
    const [people, setPeople] = useState([]);
    const [openSnackbar] = useSnackbar();
    const [isLoading, setLoading] = React.useState(false);

    const [data, setData] = React.useState([]);

    const [show, setShow] = React.useState(false);

    const [modalState, setModalState] = React.useState({});

    useEffect(() => {
        setLoading(true);

        setPeople([]);
        setData([]);

        getFinanceReportData({
            checkDate: new Date(),
            employee: "",
        }).then((data) => {
            console.log(data);
            setData(data);
        });

        listOfEmployees()
            .then((data) => setPeople(data))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    function applyFilters(filters): any {
        console.log(filters);

        setLoading(true);
        setData([]);

        getFinanceReportData(filters)
            .then((data) => {
                setData(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function changeRate(params: InvoiceRequestParams): any {
        console.log(params);
        setShow(true);
        setModalState(params);
    }

    // const handleModalWithForm = () => {
    //     const modal = createModal({
    //         component: ModalWithForm,
    //         proceed: ({ name }) => {
    //             modal.setIsLoading(true);
    //             console.log("Making API calls or doing anything here.");
    //             console.log(`Your name is: ${name}`);
    //             setTimeout(() => {
    //                 modal.close();
    //             }, 4000);
    //         },
    //     });
    //     modal.open();
    // };

    const columns = React.useMemo<ColumnDef<FinanceRecord, any>[]>(
        () => [
            {
                accessorKey: "name",
                header: "Name",
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
                footer: ({ table }) => (
                    <>
                        <span className="text-xs font-semibold">Total</span>
                    </>
                ),
            },
            {
                accessorKey: "rate",
                header: "Rate (USD)",
                cell: (info) => <Currency quantity={Number(info.getValue()) || 0} currency="USD" />,
                enableGrouping: false,
                enableColumnFilter: false,
                meta: {
                    cellClassName: "text-right",
                },
            },
            {
                accessorKey: "hrs",
                header: "Efforts (Hrs)",
                cell: (info) => Number(info.getValue()) | 0,
                enableGrouping: false,
                enableColumnFilter: false,
                meta: {
                    cellClassName: "text-right",
                },
                // footer: () => <Currency quantity={Number(_.sum(_.map(data, (d) => d.hrs))) || 0} currency="USD" />,
                footer: ({ table }) => (
                    <>
                        {/* <span className="text-xs font-normal">Total Efforts</span>
                        <br /> */}
                        {table.getFilteredRowModel().rows.reduce((total, row) => total + row.original.hrs, 0)}
                    </>
                ),
            },
            // {
            //     accessorKey: "uuid",
            //     header: "UUID",
            //     cell: (info) => info.getValue(),
            //     // enableHiding: false,
            //     enableColumnFilter: false,
            //     enableGrouping: false,
            //     // columnVisibility: false,
            //     meta: {
            //         cellClassName: "text-center",
            //     },
            // },
            {
                accessorKey: "bill",
                header: "Bill Amount (USD)",
                cell: (info) => <Currency quantity={Number(info.getValue()) || 0} currency="USD" />,
                // enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                // columnVisibility: false,
                meta: {
                    cellClassName: "text-right",
                },
                // footer: () => <Currency quantity={Number(_.sum(_.map(data, (d) => d.bill))) || 0} currency="USD" />,
                footer: ({ table }) => (
                    <>
                        {/* <span className="text-xs font-normal">Total Bill</span>
                        <br /> */}
                        <Currency quantity={Number(table.getFilteredRowModel().rows.reduce((total, row) => total + row.original.bill, 0)) || 0} currency="USD" />
                    </>
                ),
            },
            {
                accessorKey: "payment",
                header: "Amount Recieved (USD)",
                cell: (info) => <Currency quantity={Number(info.getValue()) || 0} currency="USD" />,
                // enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                // columnVisibility: false,
                meta: {
                    cellClassName: "text-right",
                },
                // footer: () => <Currency quantity={Number(_.sum(_.map(data, (d) => d.payment))) || 0} currency="USD" />,
                footer: ({ table }) => (
                    <>
                        {/* <span className="text-xs font-normal">Total Payment Recieved</span>
                        <br /> */}
                        <Currency quantity={Number(table.getFilteredRowModel().rows.reduce((total, row) => total + row.original.payment, 0)) || 0} currency="USD" />
                    </>
                ),
            },
            {
                accessorKey: "diff",
                header: "Balance",
                cell: (info) => <Currency quantity={Number(info.getValue()) || 0} currency="USD" />,
                // enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                // columnVisibility: false,
                meta: {
                    cellClassName: "text-right",
                },
                // footer: () => <Currency quantity={Number(_.sum(_.map(data, (d) => d.diff))) || 0} currency="USD" />,
                footer: ({ table }) => (
                    <>
                        {/* <span className="text-xs font-normal">Total Dues</span>
                        <br /> */}
                        <Currency quantity={Number(table.getFilteredRowModel().rows.reduce((total, row) => total + row.original.diff, 0)) || 0} currency="USD" />
                    </>
                ),
            },
            {
                accessorKey: "uuid",
                header: "",
                cell: ({ row }) => (
                    <>
                        {/* && row.original.payment > 0 */}
                        {row.original.uuid && (
                            <>
                                {/* <a target="_blank" href={`/payments/invoice/${row.original.uuid}/${row.original.m}/${row.original.year}`} className="text-blue-600 no-underline hover:text-blue-900 no-line-through">
                                    Verify <span className="sr-only">, {row.original.consultant}</span>
                                </a>
                                {" | "} */}
                                {/* href={`/payments/invoice/${row.original.uuid}/${row.original.m}/${row.original.year}`} */}
                                <a
                                    href="#"
                                    onClick={(e) =>
                                        changeRate({
                                            uuid: row.original.uuid,
                                            checkDate: moment(row.original._d).toDate(),
                                            name: row.original.name,
                                        })
                                    }
                                    className="text-blue-600 no-underline hover:text-blue-900 no-line-through"
                                >
                                    Change Rate <span className="sr-only">, {row.original.uuid}</span>
                                </a>
                            </>
                        )}
                    </>
                ),
                // enableHiding: false,
                enableColumnFilter: false,
                enableGrouping: false,
                // columnVisibility: false,
                meta: {
                    cellClassName: "text-right",
                },
            },
        ],
        []
    );

    function closeModal() {
        setShow(false);
    }

    return (
        <main>
            <Breadcrums breadcrumLinks={paymentsAdjustmentsBreadcrums} commands={paymentsCommands} groups={["Actions", "Statement"]}></Breadcrums>
            <PageTitle title="Billing Adjustments"></PageTitle>

            <section className={sectionClass}>
                <CommonFilters people={people} applyFilters={applyFilters}></CommonFilters>

                {!isLoading && data.length > 0 && <TanstackTable data={data} columns={columns} downloadBtn={true}></TanstackTable>}

                {show && <ChangeRateModal close={closeModal} show={show} _state={modalState}></ChangeRateModal>}
            </section>
        </main>
    );
}
