import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrums from "../components/breadcrums";
import { getFilteredEmployees, listOfEmployees } from "../api/consultants-api";
import PageHeader from "../components/pageHeader";
import classNames from "classnames";
import { nanoid } from "nanoid";
import Skeleton from "react-loading-skeleton";
import EmployeesToolbar from "../blocks/employeesToolbar";
import React from "react";
import PageTitle from "../components/pageTitle";
import TanstackTable from "../components/TanstackTable";

// const people1 = [{ name: "Lindsay Walton", title: "Front-end Developer", email: "lindsay.walton@example.com", role: "Member" }];

import Currency from "react-currency-formatter";
import { consultant } from "../model";
import { ColumnDef } from "@tanstack/react-table";
import { employeesbreadcrumLinks } from "../constants/breadcrums";

export default function Employees() {
    const [people, setPeople] = useState([]);

    const [isLoading, setLoading] = React.useState(false);

    const actionLinks = [{ name: "Add Employee", href: "/employees/new", className: "inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-white bg-blue-900 border border-transparent rounded-md shadow-sm order-0 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:order-1 sm:ml-3" }];

    useEffect(() => {
        setLoading(true);
        setPeople([]);

        getFilteredEmployees({ activeStatus: 1 })
            .then((data) => setPeople(data))
            .finally(() => {
                setLoading(false);
            });

        // listOfEmployees()
        //     .then((data) => {
        //         setPeople(data);
        //     })
        //     .finally(() => {
        //         setLoading(false);
        //     });
    }, []);

    function applyFilters(filters): any {
        console.log(filters);
        setLoading(true);
        setPeople([]);
        getFilteredEmployees(filters)
            .then((data) => setPeople(data))
            .finally(() => {
                setLoading(false);
            });
    }

    const columns = React.useMemo<ColumnDef<consultant, any>[]>(
        () => [
            {
                accessorKey: "name",
                header: "Name",
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
            },
            // {
            //     accessorKey: "email",
            //     header: "Email",
            //     cell: (info) => info.getValue(),
            //     enableColumnFilter: false,
            //     enableGrouping: false,
            //     // enableSorting: true,
            // },
            {
                accessorKey: "entity",
                header: "Entity",
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
            },
            {
                accessorKey: "client",
                header: "Client",
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
            },
            {
                accessorKey: "project",
                header: "Project",
                cell: ({ row }) => row.original.project, // + ", " + row.original.account,
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
            },
            {
                accessorKey: "uuid",
                header: "",
                cell: ({ row }) => (
                    <a href={`/employees/${row.original.uuid}`} className="text-blue-600 no-underline hover:text-blue-900 no-line-through">
                        View <span className="sr-only">, {row.original.name}</span>
                    </a>
                ),
                enableColumnFilter: false,
                enableGrouping: false,
                // enableSorting: true,
            },
        ],
        []
    );

    return (
        <main>
            {/* <PageHeader breadcrumLinks={breadcrumLinks} actions={actionLinks} title={"Employees"} summary={"Organizational Overview"} summaryLine={"Snapshot of Current Employee Roster and Vital Information."} /> */}

            <Breadcrums breadcrumLinks={employeesbreadcrumLinks} module={"Employees"} />

            <PageTitle title={"Employees"} preSummary={"Organizational Overview"} summary={"Snapshot of active Employees Roster and Vital Information."}>
                <Link to="/employees/new">
                    <button type="button" className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-900 border border-transparent rounded-md shadow-sm hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto">
                        Add Employee
                    </button>
                </Link>
            </PageTitle>

            <div className="px-4 py-0 sm:px-6 lg:px-8">
                <EmployeesToolbar applyFilters={applyFilters}></EmployeesToolbar>

                {people.length > 0 && <TanstackTable data={people} columns={columns} downloadBtn={true}></TanstackTable>}

                {/* <div className="flex flex-col mt-8">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                Name
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Email
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Project
                                            </th>
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {isLoading && (
                                            <>
                                                <tr>
                                                    <td className="pl-4 pr-3 text-sm font-normal text-gray-900 whitespace-nowrap sm:pl-6">
                                                        <Skeleton />
                                                    </td>
                                                    <td className="px-3 text-sm text-gray-500 whitespace-nowrap">
                                                        <Skeleton />
                                                    </td>
                                                    <td className="px-3 text-sm text-gray-500 whitespace-nowrap">
                                                        <Skeleton />
                                                    </td>
                                                    <td className="relative py-2 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                                                        <Skeleton />
                                                    </td>
                                                </tr>
                                            </>
                                        )}

                                        {!isLoading && people.length == 0 && (
                                            <>
                                                <tr>
                                                    <td colSpan={5} className="pl-4 pr-3 text-sm font-normal text-gray-900 whitespace-nowrap sm:pl-6">
                                                        <h2 className="pt-4 pb-4 text-md">No employees found</h2>
                                                    </td>
                                                </tr>
                                            </>
                                        )}

                                        {people.map((person: any, personIdx) => (
                                            <tr key={nanoid()} className={classNames(personIdx % 2 === 0 ? undefined : "bg-gray-50-x", "hover:bg-gray-100")}>
                                                <td className={classNames("pl-4 pr-3 text-sm font-normal text-gray-900 whitespace-nowrap sm:pl-6", person.active == 0 ? "line-through" : "")}>{person.name}</td>
                                                <td className={classNames("px-3 text-sm text-gray-500 whitespace-nowrap", person.active == 0 ? "line-through" : "")}>{person.email}</td>
                                                <td className={classNames("px-3 text-sm text-gray-500 whitespace-nowrap", person.active == 0 ? "line-through" : "")}>
                                                    {person.project}, {person.account}
                                                </td>
                                                <td className="relative py-2 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                                                    <a href={`/employees/${person.uuid}`} className="text-blue-600 no-underline hover:text-blue-900 no-line-through">
                                                        Profile<span className="sr-only">, {person.name}</span>
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </main>
    );
}

{
    /* <a href={`/employee/${person.uuid}`} className="px-2 text-blue-600 hover:text-blue-900">
                                                        Edit<span className="sr-only">, {person.name}</span>
                                                    </a> */
}
