import React, { createContext } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import { getConfig } from "./config";
import { Auth0Provider } from "@auth0/auth0-react";
import { CookiesProvider } from "react-cookie";
import DefaultLayout from "./layout/default";
import SnackbarProvider from "react-simple-snackbar";
const config = getConfig();

const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    // onRedirectCallback,
    authorizationParams: {
        redirect_uri: window.location.origin + "/dashboard",
        // ...(config.audience ? { audience: config.audience } : null),
    },
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <Auth0Provider {...providerConfig}>
        {/* <React.StrictMode> */}
        <CookiesProvider>
            <BrowserRouter>
                {/* <DefaultLayout> */}
                <SnackbarProvider>
                    <App />
                </SnackbarProvider>
                {/* </DefaultLayout> */}
            </BrowserRouter>
        </CookiesProvider>
        {/* </React.StrictMode> */}
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
