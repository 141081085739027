import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "react-simple-snackbar";

export default function EmployeesToolbar(props: { applyFilters: any }) {
    const { register, handleSubmit, setValue } = useForm();
    const [openSnackbar, closeSnackbar] = useSnackbar();

    const [filters, setFilters] = useState({
        activeStatus: 1,
    });

    function applyFilters(filters): any {
        props.applyFilters(filters);
    }

    const onSubmit = (data, e) => {};

    useEffect(() => {}, []);

    return (
        <div className="pt-5 space-y-6">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
                    <div className="md:grid md:grid-cols-6 md:gap-6">
                        <>
                            <div className="col-span-3 sm:col-span-1">
                                <div className="col-span-3 sm:col-span-2">
                                    <label htmlFor="uuid" className="block text-sm font-medium text-gray-700">
                                        Employee Status
                                    </label>
                                    <select
                                        {...register("activeStatus", { required: false })}
                                        id="activeStatus"
                                        name="activeStatus"
                                        defaultValue={1}
                                        className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        onChange={(e) => {
                                            setFilters({ ...filters, activeStatus: Number(e.target.value) });
                                        }}
                                    >
                                        <option value={1}>Active</option>
                                        <option value={0}>Inactive</option>
                                        <option value={-1}>All</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-span-6 sm:col-span-1">
                                <div className="pt-6">
                                    <button type="button" onClick={(e) => applyFilters(filters)} className="px-4 py-2 text-sm font-medium text-white bg-blue-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300">
                                        Apply
                                    </button>
                                    {/* &nbsp;
                                    <button type="button" className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        Reset
                                    </button>{" "} */}
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </form>
        </div>
    );
}
