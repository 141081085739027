import moment from "moment";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { addUpdateConsultant } from "../api/consultants-api";

import { useSnackbar } from "react-simple-snackbar";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { mapResponseToDTO } from "../api/mapper";

export default function ProjectDetails(props: { profile: any }) {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const schema = yup.object({
        // e_regular: yup.number().required("*required"),
    });

    let values: any = {};
    // if (props.profile.unstructured) {
    //     values = JSON.parse(props.profile.unstructured)?.paystubAnnexure;
    //     // console.log(values);
    // }

    const [openSnackbar, closeSnackbar] = useSnackbar();

    // if (props.profile) {
    // }

    const methods = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {},
        values,
    });

    const {
        register,
        handleSubmit,
        watch,
        control,
        reset,
        trigger,
        setValue,
        getValues,
        getFieldState,
        formState: { errors },
    } = methods;

    useEffect(() => {
        console.log(props.profile);
        setStartDate(moment(props.profile.start_dt).toDate());
        setEndDate(moment(props.profile.end_dt).toDate());

        if (props.profile) {
            props.profile.start_dt = moment(props.profile.start_dt).format("YYYY-MM-DD HH:mm:ss");
            props.profile.end_dt = moment(props.profile.end_dt).format("YYYY-MM-DD HH:mm:ss");
        }
    }, []);

    useEffect(() => {
        // you can do async server request and fill up form
        console.log(props.profile);
        reset(props.profile);
    }, [reset]);

    const onSubmit: SubmitHandler<any> = async (data: any) => {
        const profile = mapResponseToDTO<typeof props.profile, typeof data>(data);

        // FIXME;
        // profile.start_dt = moment(props.profile.start_dt).format("YYYY-MM-DD HH:mm:ss");
        // profile.end_dt = moment(props.profile.end_dt).format("YYYY-MM-DD HH:mm:ss");

        // Updated Start Date and End Date
        profile.start_dt = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
        profile.end_dt = moment(endDate).format("YYYY-MM-DD HH:mm:ss");

        // var finalObject = Object.assign(props.profile, data);

        console.log("Profile Object going to Database -");
        console.log(profile);

        addUpdateConsultant(profile).then((result: any) => {
            console.log(result);
            openSnackbar(result?.error ? result?.error?.sqlMessage : "Project details updated.");
        });
    };

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="space-y-6">
                        <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
                            <div className="md:grid md:grid-cols-3 md:gap-6">
                                <div className="md:col-span-1">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Project Details</h3>
                                    <p className="mt-1 text-xs text-gray-500">Client, Account & Project etc.</p>
                                </div>
                                <div className="mt-5 md:mt-0 md:col-span-2">
                                    <div className="grid grid-cols-4 gap-6">
                                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                            <label htmlFor="client" className="block text-sm font-medium text-gray-700">
                                                Client
                                            </label>
                                            <input
                                                {...register("client", {
                                                    required: false,
                                                })}
                                                type="text"
                                                name="client"
                                                id="client"
                                                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                            <label htmlFor="client_address" className="block text-sm font-medium text-gray-700">
                                                Client Address
                                            </label>
                                            <input
                                                {...register("client_address", {
                                                    required: false,
                                                })}
                                                type="text"
                                                name="client_address"
                                                id="client_address"
                                                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                            <label htmlFor="account" className="block text-sm font-medium text-gray-700">
                                                Account
                                            </label>
                                            <input
                                                {...register("account", {
                                                    required: false,
                                                })}
                                                type="text"
                                                name="account"
                                                id="account"
                                                autoComplete="street-address"
                                                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                            <label htmlFor="project" className="block text-sm font-medium text-gray-700">
                                                Project
                                            </label>
                                            <input
                                                {...register("project", {
                                                    required: false,
                                                })}
                                                type="text"
                                                name="project"
                                                id="project"
                                                autoComplete="project"
                                                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                            <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                                                Role
                                            </label>
                                            <input
                                                {...register("role", {
                                                    required: false,
                                                })}
                                                type="text"
                                                name="role"
                                                id="role"
                                                autoComplete="role"
                                                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                            <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                                                Status
                                            </label>
                                            {/* <input value={props.profile.status} type="text" name="status" id="status" autoComplete="status" className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" /> */}
                                            <select
                                                id="status"
                                                // name="status"
                                                autoComplete="status"
                                                defaultValue={""}
                                                className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                {...register("status", {
                                                    required: false,
                                                    // onChange: (e) => setFil({ ...props.profile, employee: e.target.value })
                                                })}
                                                onChange={(e) => {
                                                    props.profile.status = e.target.value;
                                                }}
                                            >
                                                <option value=""></option>
                                                <option value="A">Active</option>
                                                <option value="C">Closed</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
                                <div className="md:grid md:grid-cols-3 md:gap-6">
                                    <div className="md:col-span-1">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Project Duration</h3>
                                        <p className="mt-1 text-xs text-gray-500">Billing Type, Dates etc.</p>
                                    </div>

                                    <div className="mt-5 md:mt-0 md:col-span-2">
                                        <div className="grid grid-cols-4 gap-6">
                                            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                                <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                                    Start Date
                                                </label>

                                                <DatePicker
                                                    {...register("start_dt", {
                                                        required: false,
                                                    })}
                                                    // value={props.profile.start_dt}
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={startDate}
                                                    onChange={(date) => {
                                                        console.warn(date);
                                                        setStartDate(date);
                                                    }}
                                                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                />
                                            </div>

                                            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                                <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                                    End Date
                                                </label>

                                                <DatePicker
                                                    {...register("end_dt", {
                                                        required: false,
                                                    })}
                                                    // value={props.profile.end_dt}
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={endDate}
                                                    onChange={(date) => {
                                                        console.warn(date);
                                                        setEndDate(date);
                                                    }}
                                                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                />
                                            </div>

                                            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                                <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                                                    Billing Period
                                                </label>
                                                {/* <input value={props.profile.status} type="text" name="status" id="status" autoComplete="status" className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" /> */}
                                                <select
                                                    // value={props.profile.billing_period}
                                                    id="billing_period"
                                                    // name="status"
                                                    autoComplete="billing_period"
                                                    defaultValue={""}
                                                    className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    {...register("billing_period", {
                                                        required: false,
                                                        // onChange: (e) => setFil({ ...props.profile.billing_period, employee: e.target.value })
                                                    })}
                                                    onChange={(e) => {
                                                        props.profile.billing_period = e.target.value;
                                                    }}
                                                >
                                                    <option value="Weekly">Weekly</option>
                                                    <option value="Monthly">Monthly</option>
                                                    <option value="Monthly Itemized">Monthly Itemized</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
                                <div className="md:grid md:grid-cols-3 md:gap-6">
                                    <div className="md:col-span-1">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Project Commercials</h3>
                                        <p className="mt-1 text-xs text-gray-500">Billing Rate etc.</p>
                                    </div>

                                    <div className="mt-5 md:mt-0 md:col-span-2">
                                        <div className="grid grid-cols-4 gap-6">
                                            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                                <label htmlFor="rate" className="block text-sm font-medium text-gray-700">
                                                    Project Rate (per hour)
                                                </label>
                                                <input
                                                    {...register("rate", {
                                                        required: false,
                                                    })}
                                                    // value={props.profile.rate}
                                                    type="text"
                                                    name="rate"
                                                    id="rate"
                                                    autoComplete="rate"
                                                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-end pb-10">
                            <button type="button" className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Cancel
                            </button>
                            <button type="submit" className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-blue-900 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </>
    );
}
