import React from "react";
import { useState } from "react";
import { format } from "date-fns";
import { useSnackbar } from "react-simple-snackbar";
import { Md5 } from "ts-md5";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import { getProfileByUUID } from "../api/consultants-api";

const CalendarDate = (props) => {
    const [openSnackbar, closeSnackbar] = useSnackbar();
    const { day, index, days, hours, onSelectDay, isDataSaved } = props;
    const date = new Date(day.date);
    const [isEditMode, setIsEditMode] = useState(false);
    const [value, setValue] = useState(hours);
    const [oldValue, setOldValue] = useState(hours);
    const [isEdit, setEdit] = useState(true);
    const { user } = useAuth0();

    const [profile, setProfile] = useState({
        name: "",
        avatar: "",
        designation: "",
        email: "",
    });

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    function onDayClick() {
        setIsEditMode(true);
        setOldValue(value);
        onSelectDay(day);
    }

    function onSaveRecord() {
        console.log(value);
        if (Number(value) > 24) {
            maxhoursError("Oops, one day have 24h max!");
            return false;
        } else if (Number(value) < 0) {
            maxhoursError("Value can not be negative!");
            return false;
        } else {
            saveRecord().then((res) => {
                console.log(res);

                isDataSaved(true);
                day.hours = value;
                onSelectDay(day);

                if (res.error) {
                    openSnackbar(res.error.sqlMessage);
                } else {
                    openSnackbar("Saved");
                }
            });
        }

        setIsEditMode(false);
    }

    async function saveRecord() {
        try {
            const UTCDate = new Date(date);

            const dataObject = {
                uuid: profile?.uuid,
                name: profile?.name,
                pid: profile?.project,
                _d: UTCDate.getFullYear() + "-" + (UTCDate.getMonth() + 1) + "-" + UTCDate.getDate(), //"2023-07-01",
                paid: 0,
                rate: Number(profile?.rate) | 0,
                hrs: value === null || value === "" ? 0 : Number(value),
                bill: Number(value) * Number(profile?.rate) || 0,
                _ts_bill: moment().format("YYYY/MM/DD"),
                payment: 0,
                verified: true,
            };
            console.info(dataObject);

            const response = await fetch(process.env.REACT_APP_API_URL + "/api/finance", {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataObject),
            });
            return await response.json();
        } catch (error) {
            return [];
        }
    }

    function maxhoursError(msg) {
        openSnackbar(msg || "Invalid Number");
        setValue(oldValue || 0);
    }

    // console.log(day.date);

    return (
        <>
            <button
                key={day.date}
                type="button"
                className={classNames(
                    "hover:bg-gray-100 focus:z-10 w-full max-h-160 h-[80px]",
                    day.isCurrentMonth ? "bg-white" : "bg-gray-50",
                    (day.isSelected || day.isToday) && "font-semibold",
                    day.isSelected && "text-white",
                    !day.isSelected && day.isCurrentMonth && !day.isToday && "text-gray-900",
                    !day.isSelected && !day.isCurrentMonth && !day.isToday && "text-gray-400",
                    day.isToday && !day.isSelected && "text-indigo-600",
                    day.isWeekend ? "bg-red-50 hover:bg-red-100" : "bg-gray-50 hover:bg-gray-100",
                    day.isWeekend && value > 0 ? "bg-red-100" : "",
                    !day.isWeekend && value > 8 ? "bg-yellow-50" : "",
                    index === 0 && "rounded-tl-lg",
                    index === 6 && "rounded-tr-lg",
                    index === days.length - 7 && "rounded-bl-lg",
                    index === days.length - 1 && "rounded-br-lg"
                )}
                onClick={() => onDayClick()}
            >
                {/* {day.date.split("-").pop().replace(/^0/, "")} */}
                {!isEditMode ? (
                    <div className="h-16 pt-1">
                        <time dateTime={format(date, "yyyy-MM-dd")} className={classNames("mx-auto flex h-7 w-7 items-center justify-center rounded-full", day.isSelected && day.isToday && "bg-indigo-600", day.isSelected && !day.isToday && "bg-gray-900")}>
                            {moment(day.date).get("date")}
                            {/* {index} */}
                        </time>

                        {value > 0 && <span className={classNames("float-right text-sm", "text-green-500 m-1", day.isWeekend && value > 0 ? "bg-red-50 text-red-500 font-semibold" : "", !day.isWeekend && value > 8 ? "text-red-500 font-semibold" : "", !day.isWeekend && value < 8 ? "text-red-500 font-semibold" : "", !day.isWeekend && value === 8 ? "text-green-500" : "")}>{value}h</span>}
                    </div>
                ) : (
                    <input
                        type="number"
                        className="w-full h-16"
                        min={0}
                        max={24}
                        autoFocus
                        onFocus={(e) => e.currentTarget.select()}
                        onChange={(e) => setValue(e.target.value)}
                        onKeyDown={(e) => {
                            // console.log(e);
                            if (e.code === "Enter") {
                                setValue(e.target.value);
                                onSaveRecord();
                            }
                        }}
                        value={value}
                        onBlur={() => onSaveRecord()}
                    />
                )}
            </button>
        </>
    );
};

export default CalendarDate;
